@mixin optimizeSafariSvg($src) {
    &::before {
        transform: scale(0.25);
        transform-origin: 0 0;
        content: '';
        display: block;
        position: absolute;
        top: 0;
        width: 400%;
        height: 400%;
        background-size: contain;
        background-repeat: no-repeat;
        background-image: $src;
    }
}