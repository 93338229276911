$grey-blue: #80C2FF;
$blue-light: #E6F7FF;

body {
    .tooltip-blue {
        display: flex;
        align-items: center;
        gap: 12px;
        padding: 8px 12px;
        border-radius: 8px;
        width: fit-content;
        background-color: $blue-light;
        color: $grey-blue;
        font-size: 14px;
        border: 1px solid #80C2FF;

        &__content {
            display: flex;
            flex-direction: column;
            gap: 6px;

            .title {
                color: var(--text-accent-blue, #1890FF);
                font-family: Inter;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 16px;
            }
        }

        img {
            cursor: pointer;
        }
    }

}

.text_grey-blue {
    color: $grey-blue;
    font-size: 14px;
}