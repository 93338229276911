.enhance-photo-inside-content {
    display: flex;
    gap: 24px;
    width: 100%;

    .content-item {
        display: flex;
        width: 100%;
        padding: 16px 20px;
        flex-direction: column;
        align-items: flex-start;
        gap: 12px;
        border-radius: 8px;
        border: 2px solid #EDEFED;
        background: #FFF;

        .text_sum-photo {
            color: #475569;
            font-family: Rubik;
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;

            span {
                @extend .text_sum-photo;
                color: #8F99A3;
            }
        }

        .img-group {
            display: flex;
            gap: 12px;
            flex-wrap: wrap;

            .img-box {
                display: flex;
                width: 175px;
                height: 183px;
                overflow: hidden;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                gap: 12px;
                border-radius: 8px;
                border: 2px solid #EDEFED;
                background: white;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }
        }
    }
}