.modal-confirming-template-deletion {
    display: flex;
    flex-direction: column;
    gap: 24px;
    align-items: center;
    position: relative;
    z-index: 1002;
    display: flex;
    max-width: 480px;
    width: 100%;
    min-width: 360px;
    margin-left: auto;
    margin-right: auto;
    padding: 32px;
    flex-direction: column;
    align-items: center;
    border-radius: 8px;
    background: var(--white, #FFF);
    box-shadow: 0px 12px 32px 0px rgba(0, 0, 0, 0.12);

    &__title {
        color: #000;
        text-align: center;
        font-family: NunitoSans;
        font-size: 22px;
        font-style: normal;
        font-weight: 600;
        line-height: 32px;  
    }
    
    &__group-btn {
        display: flex;
        flex-direction: column;
        gap: 12px;
        width: 100%;
    }
}