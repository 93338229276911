.step-item-component {
    display: flex;
    flex-direction: column;
    gap: 8px;

    &__step-info {
        display: flex;
        align-items: center;
        color: #0A30CE;
        font-family: NunitoSans;
        font-size: 22px;
        font-style: normal;
        font-weight: 500;
        line-height: 28px;
        gap: 16px;
    }

    &__step-description {
        color: #000;
        font-family: NunitoSans;
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
    }
}