.home-page {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    gap: 30px;
    max-width: 1440px;
    padding-left: 20px;
    padding-right: 20px;
    margin-left: auto;
    margin-right: auto;

    .table-box-component__content,
    .table-box-component_no-data__content {
        min-height: max-content;
    }

    &__header {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        gap: 12px;

        .title_greetings {
            color: #6961BD;
            text-align: center;
            font-family: Rubik;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 104.391%;
            letter-spacing: -0.09px;
        }

        .title_main {
            color: #6961BD;
            font-family: NunitoSans;
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px; 
            margin: 0;
        }
    }

    .line_chart {
        & canvas {
            max-height: calc(100%);
        }
    }

    .card-group {
        display: flex;
        gap: 20px;
        flex-wrap: wrap;

        .card-color {
            display: flex;
            flex-direction: column;
            gap: 20px;
            min-width: 240px;
            max-width: 240px;
            min-height: 156px;
            padding: 16px 20px;
            border-radius: 8px;
            cursor: pointer;
            transition: 0.5s;

            &:hover {
                transition: 0.5s;
                transform: scale(1.15);
            }

            .title {
                color: #020950;
                font-family: NunitoSans;
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height: 16px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            .text {
                color: #373E7B;
                font-family: NunitoSans;
                font-size: 12px;
                font-style: normal;
                font-weight: 300;
                line-height: 104.391%;
            }
        }
    }

    .info-block {
        display: flex;
        flex-direction: column;
        gap: 26px;
        width: 100%;
        padding: 32px 20px;
        border-top: 1px solid #F2F3F8;
        border-bottom: 1px solid #F2F3F8;
        background: linear-gradient(92deg, #F8F9FF 6.63%, #F8F9FD 97.07%);
        margin-top: 20px;
        border-radius: 8px;

        &__content {
            display: flex;
            width: 100%;
            gap: 40px;
            justify-content: space-between;
            align-items: flex-start;
        }

        .title {
            color: #03115B;
            font-family: Rubik;
            font-size: 24px;
            font-style: normal;
            font-weight: 500;
            line-height: 104.391%;
            letter-spacing: -0.12px;
        }

        .left-content {
            display: flex;
            column-gap: 12px;
            row-gap: 47px;
            max-width: 600px;
            flex-wrap: wrap;

            .info-item {
                padding-left: 12px;
                padding-right: 12px;
                width: 100%;
                max-width: 290px;
                border-left: 1px solid #968EE9;

                .text {
                    color: #020539;
                    font-family: Rubik;
                    font-size: 13px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 20px;
                    letter-spacing: -0.065px;
                }
            }
        }

        .right-content {
            display: flex;
            align-items: flex-start;
            justify-content: center;
            width: 100%;
            max-width: calc(180px*3 + 66px*2);
            gap: 66px;
            flex-wrap: wrap;

            .info-item {
                display: flex;
                flex-direction: column;
                gap: 12px;
                width: 100%;
                max-width: 180px;
                position: relative;
                z-index: 1;

                .text_count {
                    white-space: nowrap;
                    color: #121552;
                    font-family: IBMPlexSans;
                    font-size: 48px;
                    line-height: 48px;
                    font-style: normal;
                    font-weight: 500;
                    letter-spacing: -0.24px;

                    span {
                        font-size: 20px;
                        line-height: 20px;
                    }
                }

                .text_description {
                    color: #72758E;
                    font-family: Rubik;
                    font-size: 15px;
                    font-style: normal;
                    font-weight: 300;
                    line-height: 104.391%;
                    letter-spacing: -0.075px;
                }
            }
        }
    }

    .table-block {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 32px;
        gap: 32px;
        border-top: 1px solid #E7E9FA;
        border-bottom: 1px solid #E7E9FA;
        background: #F8F9FA;
        margin-top: 70px;

        .title {
            display: flex;
            align-items: center;
            gap: 24px;
            color: #64748B;
            font-family: NunitoSans;
            font-size: 20px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px;
        }

        &__content {
            display: flex;
            width: 100%;
            gap: 16px;
        }

        &__item {
            display: flex;
            flex-direction: column;
            gap: 28px;
            width: calc(50% - 12px);
            padding: 24px;
            border-radius: 20px;
            background: #FFF;

            .table-header {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;

                .top {
                    display: flex;
                    align-items: center;
                    gap: 8px;
                    color: #51CA64;
                    font-family: Rubik;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 20px;
                    letter-spacing: -0.32px;
                }
            }

            .table-content {
                display: flex;
                flex-direction: column;
                gap: 12px;
            }
        }
    }

    .article-block {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 20px;
        margin-top: 30px;

        &__content {
            display: flex;
            width: 100%;
            gap: 12px;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
        }
    }
}