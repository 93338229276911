.table-top-5 {
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 14px;
    border-radius: 10px;
    background: #FFF;
    width: 100%;
    max-height: 600px;
    overflow-y: auto;

    &__title {
        color: #000;
        font-family: Inter;
        font-size: 17px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
    }

    &__content {
        width: 100%;
        height: 100%;
        max-height: 600px;
        overflow-y: auto;
    }

    &__header-tr {
        border-bottom: 1px solid #F1F2FD;
        background: rgba(246, 247, 246, 0.10);
    }

    &__header-td {
        min-width: 100px;
        padding: 14px 10px;
    }

    &__header-td-text {
        display: -webkit-box;
        word-break: break-word;
        -webkit-line-clamp: 2; // количество строк
        -webkit-box-orient: vertical;
        overflow: hidden;
        hyphens: auto;
        overflow: hidden;
        color: #70808F;
        text-overflow: ellipsis;
        font-family: NunitoSans;
        font-size: 11px;
        font-style: normal;
        font-weight: 800;
        line-height: 12px;
        max-height: 40px;
    }

    &__td {
        padding: 14px 10px;
        overflow: hidden;
        color: #0B110B;
        text-overflow: ellipsis;
        font-family: NunitoSans;
        font-size: 11px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
    }

    // dynamyc

    &__type-dynamyc {
        display: flex;
        gap: 12px;
        align-items: center;
    }


    &__dynamyc-sum {
        overflow: hidden;
        color: #0B110B;
        font-family: NunitoSans;
        font-size: 11px;
        font-style: normal;
        font-weight: 400;
        line-height: 13px;
        width: 53px;
    }

    // imgWithText

    &__imgWithText {
        display: flex;
        gap: 10px;
        align-items: center;
    }

    &__imgWithText-img {
        max-width: 47px;
        max-height: 47px;
    }

    &__imgWithText-text {
        display: -webkit-box;
        word-break: break-word;
        -webkit-line-clamp: 2; // количество строк
        -webkit-box-orient: vertical;
        overflow: hidden;
        hyphens: auto;
        overflow: hidden;
        color: #0B110B;
        text-overflow: ellipsis;
        font-family: NunitoSans;
        font-size: 11px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
    }

    // brand

    &__brand {
        display: flex;
        align-items: center;
        gap: 8px;
    }

    &__brand-img {
        width: 16px;
        height: 16px;
    }

    &__brand-text,  &__text {
        display: -webkit-box;
        word-break: break-word;
        -webkit-line-clamp: 2; // количество строк
        -webkit-box-orient: vertical;
        overflow: hidden;
        hyphens: auto;
        overflow: hidden;
        color: #0B110B;
        text-overflow: ellipsis;
        font-family: NunitoSans;
        font-size: 11px;
        font-style: normal;
        font-weight: 400;
        line-height: 15px;
    }
}