.stock-balances-inside-content {
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;
    height: 100%;

    .card-group {
        display: flex;
        width: 100%;
        align-items: center;
        flex-wrap: wrap;
        gap: 12px;
    }
}