.niche-search-ver2 {
    width: 64%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding-left: 20px;
    padding-right: 20px;
    max-width: 1440px;
    margin-left: auto;
    margin-right: auto;

    .title_main {
        color: #020617;
        font-family: Rubik;
        font-size: 28px;
        font-style: normal;
        font-weight: 500;
        line-height: 32px;
        margin-bottom: 12px;
        letter-spacing: -0.56px;
    }

    .toolbar-medium {
        display: flex;
        width: 100%;
        justify-content: space-between;
        gap: 24px;
        align-items: center;
    }

    .charts-and-cards-box {
      display: flex;
      gap: 10px;
      width: 100%;
      flex-direction: column;
      

      .cards-box {
          display: flex;
          flex-direction: row;
          gap: .75em;
          width: 100%;
          flex-wrap: wrap;
          justify-content: space-between
      }

      .charts-group {
          display: flex;
          width: 100%;
          gap: 8px;
          overflow: hidden;
          flex-direction: column;
      }
    }

    .toolbar-top {
        display: flex;
        width: 100%;
        gap: 16px;
        align-items: center;

        .filter-group {
            display: flex;
            gap: 13px;
            width: calc(100% );
            min-width: 416px;
            flex-wrap: wrap;
        }

        .checkbox-container {
            display: flex;
            gap: 8px;
            align-items: center;
    
            .text_label,
            .text_label_disabled {
                color: #020617;
                font-family: Rubik;
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
                line-height: 16px;
    
                &_disabled {
                    color: #D3DEE7;
                }
            }
        }
    }

    .toolbar-medium {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: start;
        align-items: end;
        gap: 30px;
        flex-wrap: wrap;

        .from-to-component-group {
            display: flex;
            gap: 20px;
            margin-top: 24px;
            flex-wrap: wrap;
            width: 100%;
            max-width: calc(100% - 245px - 30px);
            align-items: end;
        }

        .ready-made-selections-group {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            gap: 12px;
            margin-top: 24px;

            .ready-made-selections {
                display: inline-flex;
                padding: 8px;
                align-items: center;
                align-content: flex-start;
                gap: 10px;
                flex-wrap: wrap;
                border-radius: 8px;
                background: #E1EAF5;

                .text_blue {
                    color: var(--primary-400, #4DA9FF);
                    font-size: 14px;
                    font-family: Inter;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 16px;
                }
            }

        }
    }


    .table-box-component {
        margin-top: 40px;
    }

    .drop-box,
    .drop-box_hide {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 30px;
        justify-content: space-between;
        background-color: white;
        background-color: white;
        border-radius: 8px;
        padding: 30px;
        padding-right: 88px;
        margin-top: 24px;
        position: relative;
        height: -webkit-fill-available;
        min-height: calc(17px + 60px);
        max-height: 100%;
        transition: max-height 1s;

        .static-box {
            display: flex;
            align-items: center;
            gap: 10px;
            position: absolute;
            top: 28px;
            right: 30px;

            .btn__drop,
            .btn__drop_active {
                height: 32px;
                width: 32px;
                border-radius: 50%;
                background-image: url('./img/drop_grey.svg');
                background-color: #E6F7FF;
                cursor: pointer;
                z-index: 0;
                border: none;
                background-size: 14px;
                background-repeat: no-repeat;
                background-position: center;
            }
        }

        .title {
            color: #888888;
            font-size: 14px;
        }

        &_hide {
            max-height: 72px;
            height: 100%;
            overflow: hidden;
        }



        .from-to-component-group {
            display: flex;
            gap: 20px;
            margin-top: 0;
            flex-wrap: wrap;
            align-items: center;

            .from-to-component {
                background-color: #F7F7FD;
                border-radius: 8px;
                position: relative;

                .drop-content {
                    background-color: #F7F7FD;
                }

                &__header {
                    padding: 8px;
                    padding-bottom: 0;
                }

                .from-to-component__content {
                    background-color: #F7F7FD;
                }

                .btn__white-border {
                    background-color: #F7F7FD;
                }
            }
        }
    }
}