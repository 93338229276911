.advanced-analytics {
    display: flex;
    width: 100%;
    flex-direction: column;
    max-width: 1440px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 20px;
    padding-right: 20px;
    gap: 20px;
    overflow: hidden;

    &__table-chart-cards-group {
        display: flex;
        width: 100%;
        gap: 20px;

        .line_chart-wrapper,
        .line_chart {
            max-width: calc(50% - 20px - 200px);
            min-width: calc(50% - 20px - 200px);

            .line_chart {
                min-width: 100%;
                max-width: 100%;
            }
        }
    }

    &__table-chart-cards-group {
        display: flex;
        width: 100%;
        gap: 20px;

        &_cards {
            display: flex;
            flex-direction: column;
            gap: 20px;
        }
    }

    &__chart-group {
        display: flex;
        width: 100%;
        gap: 20px;

        .advanced-analytics__chart-group_line {
            width: calc(100% - 400px - 20px);
            max-width: calc(100% - 400px - 20px);
        }

        .advanced-analytics__chart-group_doughnut {
            min-width: 400px;
            max-width: 400px;
        }

        &_line {}

        &_doughnut {
            width: calc(40% - 10px);
            max-width: calc(40% - 10px);
        }
    }

    &__table-group {
        display: flex;
        width: 100%;
        gap: 20px;

        .table-top-5 {
            width: calc(50% - 10px);
            max-width: calc(50% - 10px);
        }
    }

    &__table-and-chart-group {
        display: flex;
        width: 100%;
        gap: 20px;

        .advanced-analytics__chart-group {
            width: calc(56% - 10px);
            max-width: calc(56% - 10px);
        }

        .advanced-analytics__table-group {
            width: calc(44% - 10px);
            max-width: calc(44% - 10px);
        }

        .chart-box {
            min-height: auto;
            max-height: 100%;
        }
    }
}

@media(max-width: 1440px) {
    body {
        .advanced-analytics {

            &__table-chart-cards-group {
                flex-wrap: wrap;

                .line_chart-wrapper,
                .line_chart {
                    max-width: calc(50% - 10px);
                    min-width: calc(50% - 10px);

                    .line_chart {
                        min-width: 100%;
                        max-width: 100%;
                    }
                }
            }

            &__table-chart-cards-group {
                display: flex;
                width: 100%;
                gap: 20px;

                &_cards {
                    width: 100%;
                    flex-direction: row !important;

                    .сard-metrics-ver-2 {
                        max-width: calc(50% - 10px);
                        width: 100%;
                    }
                }
            }

            &__chart-group {
                display: flex;
                width: 100%;
                gap: 20px;

                .advanced-analytics__chart-group_line {
                    width: calc(50% - 10px);
                    ;
                    max-width: calc(50% - 10px);
                    ;
                }

                .advanced-analytics__chart-group_doughnut {
                    min-width: calc(50% - 10px);
                    ;
                    max-width: calc(50% - 10px);
                    ;
                }

                &_line {}

                &_doughnut {
                    width: calc(40% - 10px);
                    max-width: calc(40% - 10px);
                }
            }

            &__table-group {
                display: flex;
                width: 100%;
                gap: 20px;

                .table-top-5 {
                    width: calc(50% - 10px);
                    max-width: calc(50% - 10px);
                }
            }

            .advanced-analytics__table-group {
                flex-direction: column;
                width: 100%;
                max-width: 100%;

                .table-top-5 {
                    width: 100%;
                    max-width: 100%;
                }
            }

            &__table-and-chart-group {
                flex-wrap: wrap;

                .advanced-analytics__chart-group {
                    width: 100%;
                    max-width: 100%;
                }

                .advanced-analytics__table-group {
                    flex-direction: row;
    
                    .table-top-5 {
                        width: calc(50% - 10px);
                        max-width: calc(50% - 10px);
                    }
                }

                .chart-box {
                    min-height: auto;
                    max-height: 100%;
                }
            }
        }
    }

}