.button-action-drop {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    &__btn,
    &__btn-medium {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        border-radius: 8px;
        background: #FAFBFA;
        border: none;
        width: 40px;
        min-width: 40px;
        height: 40px;
    }

    &__btn-medium {
        border-radius: 4px;
        width: 32px;
        min-width: 32px;
        height: 32px;
    }

    &__btn-small {
        border-radius: 4px;
        width: 16px;
        min-width: 16px;
        height: 16px;

        img {
            transform: rotate(90deg);
            width: 14px;
            height: 14px;
        }
    }

    &__drop-menu,
    &__drop-menu_active {
        position: fixed;
        top: 0;
        left: 0;
        width: 160px;
        height: auto;
        display: flex;
        flex-direction: column;
        overflow: hidden;
       // opacity: 0;
        visibility: hidden;
       // transition: 0.3s;
        z-index: 5;
        z-index: 100;
        padding-top: 6px;

        &_active {
            opacity: 1;
            visibility: visible;
           // transition: 0.3s;
        }

        &__btn {
            padding: 8px;
            width: 100%;
            min-height: 32px;
            background-color: inherit;
            border: none;
            text-align: left;
            color: #3A3A3A;
            font-family: NunitoSans;
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: 14px;


            &:hover {
              //  transition: 0.3s;
                background-color: #F3F3F3;
            }
        }
    }

    &__drop-menu-inside {
        display: flex;
        flex-direction: column;
        border-radius: 8px;
        border: 1px solid #E0E7F2;
        background: #fff;
        box-shadow: 0px 4px 4px 0px rgba(30, 41, 59, 0.06);
        padding-top: 4px;
        padding-bottom: 4px;
    }
}