.button-copy {
    position: absolute;
    top: calc(50% - 8px);
    right: 0;

    .tooltip_black {
        opacity: 0;
        visibility: hidden;
        background-color: #4A4A4A;
        border-radius: 2px;
        overflow: hidden;
        color: white;
        position: absolute;
        top: 90%;
        left: calc(30px);
        z-index: 100;
        width: max-content;
        height: auto;
        padding: 2px 4px;
        border: 1px solid #E0E7F2;
    }

    &:hover {
        .tooltip_black {
            opacity: 1;
            visibility: visible;
            transition: 0.5s;
        }
    }

    .btn-copy {
        background-image: url('./img/copy.svg');
        width: 16px;
        height: 16px;
        min-width: 16px;
        border: none;
        background-color: inherit;
        background-repeat: no-repeat;
        background-position: center;
        position: absolute;
    }

}