.breadcrumbs {
    display: flex;
    align-items: center;
    gap: 12px;
    font-size: 14px;

    .breadcrumb-item:not(:last-child) {
        text-wrap: nowrap;
        
        &::after {
            margin-left: 15px;
            content: "->";
        }
    }
}