.answer-templates {
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;
    max-width: calc(1440px - 40px);
    margin-left: auto;
    margin-right: auto;

    &__content {
        border: 1px solid #EAEBFC;
        border-radius: 8px;
        width: 100%;
        max-width: 1000px;
        min-height: 640px;
        max-height: 640px;
        background-color: #fff;
    }

    &__header-top,
    &__header-bottom {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 12px;
        width: 100%;
        border-bottom: 1px solid #EDEFED;
        background-color: #fff;
        border-radius: 8px 8px 0 0;
        padding: 10px 16px;

        .input-dynamic-width-bar {
            width: 100%;
            max-width: 100%;
            border-radius: 8px;
        }
    }

    &__header-btn-group-bottom {
        display: flex;
        gap: 12px;
        margin-left: auto;

        .btn {
            min-width: 200px;
            display: flex;
            justify-content: center;
            height: 40px;
            padding: 4px 12px;
            align-items: center;
            gap: 8px;
            align-self: stretch;
            border-radius: 8px;
            background: #FAFBFA;
            border-radius: 8px;
            border: 1px solid #EDEFED;
            overflow: hidden;
            color: #555C9E;
            text-overflow: ellipsis;
            font-family: Rubik;
            font-size: 11px;
            font-style: normal;
            font-weight: 500;
            line-height: 14px;
        }
    }

    &__header-bottom {
        justify-content: space-between;

        .tab-sort {
            overflow: hidden;
            width: auto;
            display: flex;
            height: 40px;
            border-radius: 8px;
            border: 1px solid #EDEFFB;
            margin-left: 0 !important;
    
            .btn,
            .btn_active {
                display: flex;
                align-items: center;
                min-width: 120px;
                gap: 6px;
                justify-content: center;
                height: 100%;
                cursor: pointer;
                border-left: 1px solid var(--table-border, #EDEFFB);
                border: none;
                background-color: #fff;
                padding-left: 16px;
                padding-right: 16px;
                color: var(--Slate-950, #020617);
                font-family: Rubik;
                font-size: 12px;
                font-style: normal;
                font-weight: 300;
                line-height: 14px;
                border-left: 1px solid #EDEFFB;
    
                &:hover,
                &_active {
                    transition: 0.5s;
                    background: #51CA64;
                    color: white;
    
                    path {
                        fill: #fff
                    }
                }
    
                &:nth-child(1) {
                    border: none;
                }
    
                .arrow,
                .arrow_up {
                    min-width: 16px;
                    width: 16px;
                    height: 16px;
    
                    &_up {
                        transform: rotate(180deg);
                    }
                }
            }
        }
    }

    &__header-btn-group {
        display: flex;
        gap: 12px;
        width: 100%;
        align-items: center;
    }

    &__templates-container {
        display: flex;
        flex-direction: column;
        gap: 8px;
        width: 100%;
        padding: 16px;
        max-height: calc(640px - 122px);
        overflow: auto;
        padding-bottom: 100px;
    }
}