.settings-row {
    display: flex;
    width: 100%;
    gap: 24px;

    &__name,
    &__tools {
        display: flex;
        align-items: center;
        width: 50%;
        gap: 12px;
    }

    &__name-label {
        overflow: hidden;
        color: #020617;
        text-overflow: ellipsis;
        font-family: "Open Sans";
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 140%;
    }

    &__icon {
        width: 42px;
        height: 42px;
    }

    &__label {
        overflow: hidden;
        color: #020617;
        text-overflow: ellipsis;
        font-family: "Open Sans";
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 140%;
    }

    .btn-basic {
        margin-left: auto;
    }
}