.account-notifications {
    display: flex;
    flex-direction: column;
    gap: 24px;

    &__description {
        color: #000;
        font-family: Rubik;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 1.2;
    }

    &__description-and-btn {
        display: flex;
        gap: 24px;
        align-items: center;
    }

    &__top-content {
        display: flex;
        gap: 24px;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }

    &__available,
    &__aсtive {
        width: 100%;

        .drop-content {
            display: flex;
            flex-direction: column;
            width: 100%;
            gap: 16px;
            margin-top: 20px;
            height: 100%;
            min-height: 150px;

            .notifications-item {
                cursor: grab;
                display: flex;
                width: 100%;
                padding: 14px 24px;
                align-items: center;
                gap: 16px;
                border-radius: var(--space-x-2, 8px);
                border: 1px solid var(--stroke-primary-primary, #E0E7F2);
                background: var(--form-bg, #F5F7F8);

                &:active {
                    cursor: grabbing;
                }

                img {
                    width: 20px;
                    height: 20px;
                    border-radius: 50%;
                }
            }
        }


    }

    .col-group {
        display: flex;
        gap: 24px;
    }

    .col-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        border-radius: 8px;
        border: 2px solid var(--Stroke-Primary-Primary, #EDEFFB);
        background: #F8F9FE;
        box-shadow: 0px 15px 20px 0px rgba(30, 41, 59, 0.06);
        max-width: 390px;
        width: 390px;
        position: relative;

        &__header {
            display: flex;
            width: 100%;
            padding: 12px 16px;
            justify-content: center;
            align-items: center;
            gap: 8px;
            border-bottom: 2px solid var(--Stroke-Primary-Primary, #EDEFFB);
            border-radius: 8px 8px 0px 0px;
            background: #FFF;
            min-height: 76px;

            .title {
                color: #64748B;
                text-align: center;
                font-family: Inter;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                margin: 0;

                span {
                    color: #020617;
                }
            }

            .info-icon {
                cursor: pointer;
            }
        }

        &__list {
            display: flex;
            flex-direction: column;
            gap: 8px;
            width: 100%;
            height: 100%;
            overflow-y: auto;
            overflow-x: hidden;
            max-height: 60vh;
            min-height: 60vh;
            padding: 8px;

            &::-webkit-scrollbar {
                width: 0;
            }

            .no-list {
                display: flex;
                flex-direction: column;
                gap: 6px;
                align-items: center;
                width: 100%;
                height: fit-content;
                margin-top: 200px;
                justify-content: center;

                img {
                    width: 45px;
                    height: 45px;
                }

                .text {
                    color: #94A3B8;
                    text-align: center;
                    font-family: Inter;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 16px;
                }
            }



            &[loading='true'] {
                pointer-events: none;
                opacity: 0.3;
            }
        }

        &__card {
            display: flex;
            width: auto;
            padding: 12px;
            flex-direction: column;
            justify-content: space-between;
            gap: 8px;
            border-radius: 12px;
            border: 2px solid var(--Stroke-Primary-Primary, #EDEFFB);
            background: #ffffff;
            cursor: grab;
            min-height: 100px;

            .text {
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
                hyphens: auto;
                color: #020617;
                font-family: Rubik;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 16px;
                min-height: 32px;
            }

            .status-content {
                display: flex;
                justify-content: space-between;
                align-items: center;
                gap: 8px;
                width: 100%;

                .btn-text-violet {
                    border: none;
                    background-color: inherit;
                    color: #3900B1;
                    font-family: NunitoSans;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 14px;
                    cursor: pointer;
                }

                .status {
                    display: flex;
                    min-height: 18px;
                    height: 18px;
                    padding: 4px 8px;
                    align-items: center;
                    justify-content: center;
                    border-radius: 4px;
                    background: #F1F5F9;
                    color: var(--slate-400, #94A3B8);
                    font-family: Rubik;
                    font-size: 11px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 14px;
                }

                .card-disactive {
                    @extend .status;
                    background: #F1F5F9;
                    color: #94A3B8;
                }

                .card-active {
                    @extend .status;
                    background-color: #86EFAC;
                    color: #15803D;
                }
            }
        }

        .green-btn {
            align-self: flex-end;
        }
    }
}