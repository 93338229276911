.checkbox_container_blue {

    .checkbox {
        opacity: 0;
        position: fixed;
        width: 1px;
        height: 1px;
        clip: rect(1px, 1px, 1px, 1px);
    }
}

.checkbox_blue, .checkbox_blue_err {
    display: inline-block;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    vertical-align: middle;
    
}

.checkbox_blue::before, .checkbox_blue_err::before {
    content: "";
    display: inline-block;
    width: 16px;
    height: 16px;
    left: 344px;
    top: 321px;
    // authorization box differences
    border: none;
    background-color: #FAFAFA;;
    //
    border-radius: 2px;
    vertical-align: middle;
    border: 1.5px solid var(--status-grey, #BDBCDB);
}

.checkbox_blue_err::before {
    border: 1.5px solid var(--status-grey, #F93232);
    background-color: #FFF0F0;
}


.checkbox:checked~.checkbox_blue::before {
    // differences
    background-image: url('./img/check.svg');
    background-color: #1890FF;
    //
    vertical-align: middle;
    background-repeat: no-repeat;
    background-position: center;
}

.checkbox:checked~.checkbox_blue_err::before {
    // differences
    background-image: url('./img/check.svg');
    background-color: #1890FF;
    //
    vertical-align: middle;
    background-repeat: no-repeat;
    background-position: center;
}
