.password-recovery {
    position: relative;
    z-index: 1000;
    display: flex;
    max-width: 480px;
    width: 100%;
    gap: 28px;
    margin-left: auto;
    margin-right: auto;
    padding: 24px 32px;
    flex-direction: column;
    align-items: center;
    border-radius: 8px;
    background: var(--white, #FFF);
    box-shadow: 0px 12px 32px 0px rgba(0, 0, 0, 0.12);

    .title {
        padding: 0;
        margin: 0;
        font-size: 24px;
        font-weight: 500;
    }

    .btn__blue {
        min-height: 40px;
        min-width: 100%;
        max-width: 100%;
        border-radius: 8px;
        font-size: 14px;
    }

    .btn-close {
        top: 10px;
        right: 10px;
        display: inline-block;
        position: absolute;
        background-image: url('./img/close.svg');
        width: 20px;
        height: 20px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: 20px;
        border: none;
        background-color: inherit;
    }
}