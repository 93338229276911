.filters-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: auto;
    gap: 12px;

    .btn-reset-filters {
        height: 40px;
        width: 24x;
        min-width: 24px;
        padding: 8px;
        gap: 10px;
        background-color: inherit;
        background-image: url('./img/close_img.svg');
        background-size: 20px;
        background-repeat: no-repeat;
        background-position: center;
        border: none;

        &:hover {

        }
    }
}