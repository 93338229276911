@import '../../../common/styles/mixins.scss';


.modal-add-connection {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    z-index: 1000;
    height: 100%;
    max-height: 80vh;
    display: flex;
    max-width: 1150px;
    width: 100%;
    min-width: 360px;
    margin-left: auto;
    margin-right: auto;
    flex-direction: column;
    align-items: center;
    border-radius: 8px;
    background: var(--white, #FFF);
    box-shadow: 0px 12px 32px 0px rgba(0, 0, 0, 0.12);

    &__header {
        display: flex;
        width: 100%;
        align-items: center;
        gap: 40px;
        width: 100%;
        padding: 24px 56px;

        .title {
            color: #020617;
            font-family: Inter;
            font-size: 20px;
            font-style: normal;
            font-weight: 500;
            line-height: 16px;
        }

        .selector_wrapper {
            max-width: 288px;
        }
    }

    .select-group {
        display: flex;
        width: 100%;
        width: 100%;
        padding: 20px 56px;
        gap: 24px;
        border-top: 1px solid #EDEFFB;
        border-bottom: 2px solid #EDEFFB;
        background: #FCFCFF;

        .selector_wrapper {
            width: calc(50% - 12px);
            max-width: 100%;
        }
    }

    &__content {
        display: flex;
        height: 64px;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: flex-start;
        width: 100%;
        gap: 24px;
        box-sizing: border-box;
        background-color: #fff;
        border-radius: 16px;
        height: auto;
        margin-left: auto;
        margin-right: auto;
        padding: 24px 56px;
        overflow: auto;

        .notification-item,
        .notification-item_active {
            display: flex;
            gap: 8px;
            width: 150px;
            min-width: 150px;
            height: 104px;
            padding: 10px;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            border-radius: 8px;
            border: 2px solid #EDEFFB;
            background: #FFF;
            cursor: pointer;
            position: relative;

            .btn-basic {
                display: none;
            }

            .text {
                overflow: hidden;
                color: #020617;
                text-align: center;
                text-overflow: ellipsis;
                white-space: nowrap;
                font-family: Rubik;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 16px;
            }

            .changing-content {
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                min-height: 32px;
            }

            &:hover {
                border: 2px solid #3900B1;
                transition: 0.5s;


                .btn-basic {
                    display: inline-block;
                }
            }

            &_active {
                border: 2px solid #3900B1;

                .text {
                    display: inline-block;
                }
            }

            img {
                margin-left: auto;
                margin-right: auto;
                object-fit: contain;
                width: 40px;
                height: 40px;
            }
        }
    }

    &__bottom-content {
        display: flex;
        flex-direction: column;
        gap: 24px;
        align-items: center;
        width: 100%;
        justify-content: center;
        width: 100%;
        padding: 24px 56px;
        margin-top: auto;

        .text {
            color: #2E3552;
            font-family: Rubik;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;

            .link__blue {
                text-decoration: underline;
                color: #1890FF;
                cursor: pointer;
            }
        }
    }



    .btn__blue {
        margin-top: 24px;
        max-width: 560px;
    }
}

.modal-autorization,
.modal-autorization-connection {
    display: block;
    max-width: 960px;
    width: 100%;
    height: auto;
    border-radius: 16px;
    min-height: 520px;
    background-color: #F5F5F5;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    margin-top: 60px;
    z-index: 1000;
    padding: 12px;
    position: relative;

    .text {
        overflow: hidden;
        color: #020617;
        text-align: center;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-family: Rubik;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
    }

    &_loader {
        display: block;
        max-width: 960px;
        width: 100%;
        height: auto;
        border-radius: 16px;
        min-height: 520px;
        background-color: #F5F5F5;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        margin-top: 60px;
        z-index: 1000;
        padding: 40px 40px 16px;
        position: relative;
        max-width: 800px;

        &:before {
            content: '';
            display: block;
            background-color: white;
            position: absolute;
            opacity: 0.5;
            z-index: 2000;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            border-radius: 16px;
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        gap: 24px;
    }

    & .title {
        text-align: center;
    }

    &__select-group {
        width: 100%;
        display: flex;
        flex-wrap: nowrap;
        justify-content: center;
        height: auto;
        column-gap: 24px;
        row-gap: 16px;

        & .btn__green {
            margin-top: 0;
            max-width: 200px;
            white-space: nowrap;
            padding-left: 8px;
            padding-right: 8px;
        }
    }

    &__box-icon {
        display: flex;
        height: 64px;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: flex-start;
        width: 100%;
        gap: 3em 2.5em;
        box-sizing: border-box;
        background-color: #fff;
        border-radius: 16px;
        min-height: 312px;
        height: auto;
        max-height: calc(100vh - 120px - 250px);
        margin-left: auto;
        margin-right: auto;
        padding: 25px 20px;
        padding-left: 40px;
        overflow: auto;
    }

    &__bottom-content {
        display: flex;
        width: 100%;
        justify-content: center;
    }
}

.modal-autorization-connection {
    max-width: 800px;
}


.error_warning {
    color: transparent;
    font-size: 15px;
    letter-spacing: 1px;
    display: inline-block;
    margin-top: 8px;
}

.error_warning.active {
    color: rgb(211, 21, 21);
}

.btn__icon {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    height: auto;
    gap: .5em;
    width: 64px;
    height: 64px;
    background-color: rgb(255, 255, 255);
    cursor: pointer;
    border: none;
    outline: 1px dashed #D9D9D9;
    border-radius: 16px;

    .clicker {
        position: absolute;
        width: 100%;
        height: 100%;
        background: none;
        border: none;
    }

    &:hover {
        outline-offset: -2px;
        outline: 2px solid #B1E1F8;
        -webkit-box-shadow: 1px 0px 19px 8px rgba(177, 225, 248, 0.26);
        -moz-box-shadow: 1px 0px 19px 8px rgba(177, 225, 248, 0.26);
        box-shadow: 1px 0px 19px 8px rgba(177, 225, 248, 0.26);
        -webkit-transition: all 10ms ease;
        -moz-transition: all 10ms ease;
        -ms-transition: all 10ms ease;
        transition: all 10ms ease;
    }

    &.selected_service {
        outline-offset: -2px;
        outline: 2px solid #B1E1F8;
        -webkit-box-shadow: 1px 0px 19px 8px rgba(177, 225, 248, 0.26);
        -moz-box-shadow: 1px 0px 19px 8px rgba(177, 225, 248, 0.26);
        box-shadow: 1px 0px 19px 8px rgba(177, 225, 248, 0.26);
    }

    &>embed {
        pointer-events: all;
        display: inline-block;
        width: 100%;
        height: 100%;
        max-width: 64px;
        max-height: 64px;
        min-width: 64px;
        min-height: 64px;
        border-radius: 16px;
        background-image: url('../../../common/img/imageNotFound.png');
        background-repeat: no-repeat;
        background-size: 100%;
        overflow-x: hidden;
        overflow-y: hidden;
    }

}

.box-icon {
    display: flex;
    flex-wrap: wrap;
    gap: 32px;
}