.tariffs-plan {
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    height: 100%;

    .content-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;
    }

    &__header {
        display: flex;
        width: 100%;
        align-items: center;
        gap: 24px;
        justify-content: space-between;
    }

    &__title {
        color: #0C2037;
        font-family: Rubik;
        font-size: 28px;
        font-style: normal;
        font-weight: 500;
        line-height: 32px;
        letter-spacing: -0.56px;
        margin-bottom: 12px;
    }

    .promo-code {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 16px;
        margin-top: 24px;

        .loader_box {
            width: fit-content;
            height: fit-content;
            position: absolute;
            top: calc(50% - 16px);
            right: calc(62px - 12px);
        }

        .text {
            color: var(--all-text-secondary-text, #475569);
            font-family: Inter;
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: 28px;
        }

        &__container-input {
            display: flex;
            position: relative;

            .input-grey {
                display: flex;
                padding: 8px 12px 8px 8px;
                padding-right: 144px;
                width: 100%;
                min-width: 328px;
                max-width: 328px;
                min-height: 40px;
                border-radius: 8px;
                background: #EDF3F8;
                border: none;
                text-align: center;

                &:placeholder {
                    color: #94A3B8;
                    text-align: center;
                    font-family: Inter;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 16px;
                }

                &:focus {
                    outline: none;
                }
            }

            .text_err {
                position: absolute;
                top: 40px;
                color: #EF4444;
                font-family: Inter;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }

            .btn-grey,
            .btn-grey_active {
                width: 124px;
                height: 40px;
                top: 0;
                right: 0;
                position: absolute;
                gap: 8px;
                border-radius: 8px;
                background: #E1E6EA;
                border: none;

                &_active {
                    background-color: white;
                    color: #00B45E;
                    border: 1px solid #00B45E;
                }


            }
        }
    }

    .title_main {
        color: #00284D;
        font-family: Inter;
        font-size: 40px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-bottom: 24px;
        text-align: center;
        margin-top: 52px;
    }

    &__top {
        display: flex;
        flex-direction: column;
        gap: 40px;
        padding: 24px;
        background-color: white;
        border-radius: 8px;
        max-width: 100%;
        width: 100%;
        border-radius: 10px;
        border: 2px solid #F2F2F4;
        background: #FFF;
        margin-left: auto;
        margin-right: auto;
        min-width: 800px;

        .title_tariffs {
            color: #0C2037;
            font-family: Rubik;
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
        }
    }

    .cards-group {
        display: flex;
        flex-direction: column;
        gap: 24px;
        flex-wrap: wrap;

        .cards-min-grouop {
            display: flex;
            flex-wrap: wrap;
            display: flex;
            border-radius: 8px;
            width: fit-content;
            margin-left: auto;
            width: 75%;
            justify-content: center;

            .tariff-cards-min,
            .tariff-cards-min_active {
                display: flex;
                width: 100%;
                min-width: 160px;
                max-width: 160px;
                padding: 8px 16px;
                align-items: center;
                gap: 10px;
                border-radius: 8px;
                color: var(--Base-Black, #000);
                font-family: Rubik;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                cursor: pointer;
                justify-content: center;

                .discount {
                    display: flex;
                    padding: 2px 4px;
                    align-items: center;
                    height: 14px;
                    gap: 8px;
                    border-radius: 4px;
                    background: #fff;
                    min-width: 36px;
                    width: auto;
                    max-width: 36px;
                    color: #333;
                    font-family: Rubik;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    white-space: nowrap;
                    line-height: 16px;
                }

                p {
                    display: flex;
                    align-items: center;
                    gap: 10px;
                    white-space: nowrap;

                    .round {
                        background-color: white;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 20px;
                        min-width: 20px;
                        height: 20px;
                        border: 1px solid #00B45E;
                        border-radius: 50%;
                    }
                }
            }

            .tariff-cards-min_active {
                transition: 0.3s;
                background-color: #00B45E;

                p {
                    color: white;

                    .round {
                        span {
                            transition: 0.3s;
                            width: 10px;
                            height: 10px;
                            border-radius: 50%;
                            background-color: #00B45E;
                        }
                    }
                }
            }

            .tariff-cards-min:hover {
                transition: 0.3s;
                background-color: #00B45E;
                color: #fff;

                p {
                    .round {
                        span {
                            background-color: white;
                        }
                    }
                }
            }
        }
    }

    .tariff-cards-group {
        display: flex;
        gap: 28px;
        flex-wrap: wrap;
        margin-left: auto;
        margin-right: auto;


    }

    .tariff-card {
        display: flex;
        max-width: 100%;
        min-width: 100%;
        padding: 20px;
        flex-direction: column;
        gap: 22px;
        border-radius: 10px 10px 0 0;
        border: 2px solid #F2F2F4;
        border-bottom: none;
        background: #FFF;

        .badge-box {
            display: flex;
            flex-wrap: wrap;
            gap: 12px;

            .badge,
            .badge_grey {
                display: flex;
                align-items: center;
                background-color: white;
                border-radius: 6px;
                font-family: Inter;
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
                line-height: 16px;
                color: #1890FF;
                padding-left: 42px;
                padding-right: 8px;
                min-height: 20px;
                position: relative;
                border: 1px solid #95CBFE;

                &::before {
                    content: '';
                    display: inline-block;
                    width: 6px;
                    height: 6px;
                    border-radius: 50%;
                    background-color: #1890FF;
                    position: absolute;
                    left: 8px;
                }

                &::after {
                    content: '+';
                    display: inline-block;
                    font-size: 18px;
                    border-radius: 50%;
                    color: #1890FF;
                    position: absolute;
                    left: 22px;
                    top: calc(50% - 9px);
                }
            }

            .badge_grey {
                border: 1px solid #E1E6EA;
                color: #52525B;

                &::before {
                    background-color: #71717A;
                }

                &::after {
                    color: #71717A;
                }
            }
        }

        .name-box {
            display: flex;
            justify-content: space-between;

            .name {
                color: #000;
                font-family: Rubik;
                font-size: 24px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
            }
        }

        .sum-box {
            display: flex;

            span {
                color: var(--icons-active, #00B45E);
                font-family: Rubik;
                font-size: 24px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;

                span {
                    color: var(--icons-active, #00B45E);
                    font-family: Rubik;
                    font-size: 24px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                }
            }
        }
    }

    .tariffs-table {
        th {
            width: calc(100% / 4);
            padding-left: 32px;
        }

        tr {


            .text_label {
                color: #2B4E6A;
                font-family: Rubik;
                font-size: 20px;
                font-style: normal;
                font-weight: 500;
                line-height: 24px;
            }

            .td-content {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-left: 32px;
                padding-left: 16px;
                padding-right: 16px;
                height: 100%;
                border-right: 2px solid #EDEFFB;
                border-left: 2px solid #EDEFFB;
                padding: 10px;
            }

            td {
                border-bottom: 2px solid var(--Stroke-Primary, #EDEFFB);
            }
        }
    }

    .parameter_string {
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin-top: 40px;

        .title {
            color: var(--text-primary-blue, #020617);
            font-family: Inter;
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
        }

        &__content {
            display: flex;
            width: 100%;
            flex-direction: column;
            gap: 12px;

            .parameter-item {
                display: flex;
                width: 100%;
                padding: 8px 12px;
                align-items: center;
                border-radius: 8px;
                background: #FFF;

                .status-box {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    max-width: 23%;
                    width: 100%;
                }

                .discount-box {
                    width: 11%;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    .discount {
                        display: flex;
                        padding: 0px 8px;
                        min-height: 20px;
                        padding-left: 28px;
                        align-items: center;
                        gap: 8px;
                        border-radius: 6px;
                        border: 1px solid #E1E6EA;
                        background: #31AA27;
                        min-width: 70px;
                        width: auto;
                        max-width: 70px;
                        color: #F7FDFC;
                        text-align: center;
                        font-family: Inter;
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 500;
                        white-space: nowrap;
                        line-height: 16px;
                        background-image: url('./img/discount-icon.svg');
                        background-position: center left 8px;
                        background-repeat: no-repeat;
                    }

                    .orange {
                        background-color: #E99518;
                    }

                    .blue {
                        background-color: #0161B9;
                    }
                }
            }

            .text_grey {
                width: 20%;
                color: var(--text-grey, #70808F);
                font-family: Inter;
                font-size: 18px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                hyphens: auto;
            }

        }
    }
}