.modal-review {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 658px;
    min-width: 360px;
    position: relative;
    max-height: calc(100vh - 40px);
    height: auto;
    border-radius: 16px;
    background-color: rgb(255, 255, 255);
    margin-left: auto;
    margin-right: auto;
    overflow: hidden;

    .chat__content {
        display: flex;
        flex-direction: column;
        gap: 24px;
        padding: 24px;
        overflow: auto;
        width: 100%;
        height: 100%;
        min-height: 425px;
        border-top: 2px solid #EDEFFB;
        background-color: #FBFBFD;

        .date-messages {
            border-radius: 100px;
            margin-left: auto;
            margin-right: auto;
            width: fit-content;
            padding: 8px 12px;
            background: #FFF;
            box-shadow: 3px 5px 10px 0px rgba(13, 17, 52, 0.16);
            color: #2E3552;
            text-align: center;
            font-family: Rubik;
            font-size: 11px;
            font-style: normal;
            font-weight: 400;
            line-height: 12px;
            position: relative;
            z-index: 1;

            &:first-child {
                margin-top: 0;
            }
        }

        .product-card {
            display: flex;
            width: 100%;
            padding: 12px 24px;
            min-width: 0;
            gap: 12px;
            border-radius: 12px;
            background: var(--main-menu-active-row, #E6F7FF);
            margin-top: 24px;

            .icon-container {
                width: 52px;
                max-width: 52px;
                min-width: 52px;
                border-radius: 8px;

                img {
                    width: 100%;
                    object-fit: cover;
                }
            }

            &__content {
                display: flex;
                flex-direction: column;
                gap: 24px;
                width: 100%;

                .top-and-date {
                    display: flex;
                    width: 100%;
                    align-items: center;
                    justify-content: space-between;
                    gap: 16px;

                    p {
                        color: var(--main-menu-teritary-text, #94A3B8);
                        font-family: Inter;
                        font-size: 13px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;

                        span {
                            color: var(--main-menu-secondary-text, #475569);
                            font-family: Inter;
                            font-size: 13px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: normal;
                        }
                    }

                    .rating {
                        display: flex;
                        align-items: center;
                        gap: 2px;

                        span {
                            color: var(--text-main-text, #020617);
                            /* Table/Content/M */
                            font-family: IBMPlexSans;
                            font-size: 13px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 14px;
                        }
                    }

                    .date {
                        color: var(--main-menu-secondary-text, #475569);
                        text-align: right;
                        /* Основной текст/Мелкий */
                        font-family: Inter;
                        font-size: 11px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        letter-spacing: -0.24px;
                    }
                }

                .info-content {
                    display: flex;
                    flex-direction: column;
                    width: 100%;


                    p {
                        color: var(--main-menu-teritary-text, #94A3B8);
                        font-family: Inter;
                        font-size: 13px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;

                        

                        span {
                            color: var(--main-menu-secondary-text, #475569);
                            font-family: Inter;
                            font-size: 13px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: normal;
                        }
                    }
                }
            }


        }

        .message-and-icon {
            display: flex;
            gap: 12px;
            width: 100%;
            align-items: flex-end;

            .img-box {
                width: 36px;
                min-width: 36px;
                height: 36px;
                border-radius: 4px;
                overflow: hidden;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }
        }

        .delimiter-user {
            margin-top: 26px;
            margin-bottom: 26px;
            height: 1px;
            width: 100%;
            background-color: #EAEBFC;
            position: relative;

            .text_date {
                white-space: nowrap;
                width: fit-content;
                position: relative;
                background-color: #fff;
                color: #2E3552;
                text-align: center;
                font-family: Rubik;
                font-size: 11px;
                font-style: normal;
                font-weight: 400;
                line-height: 12px;
                top: -6px;
                margin-left: auto;
                margin-right: auto;
                padding-left: 24px;
                padding-right: 24px;
                z-index: 1;
            }
        }

        .message-item {
            display: flex;
            flex-direction: column;
            gap: 8px;
            max-width: 70%;
            width: auto;

            .img-group {
                display: flex;
                width: 100%;
                flex-wrap: wrap;
                gap: 10px;
                align-items: center;
                margin-left: 48px;

                .img-box {
                    width: 64px;
                    height: 64px;
                    border-radius: 4px;
                    border: 1px solid #C5CCD3;
                    overflow: hidden;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
            }


            .info-box {
                display: flex;
                flex-direction: column-reverse;
                align-items: end;              
                justify-content: space-between;
                width: calc(100% - 48px);
                gap: 4px;
                margin-left: 48px;

                .btn-edit {
                    display: flex;
                    gap: 5px;
                    align-items: center;
                    border: none;
                    background-color: inherit;
                    cursor: pointer;
                    overflow: hidden;
                    color: #94A3B8;
                    text-overflow: ellipsis;
                    font-family: Rubik;
                    font-size: 10px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 18px;

                }

                .date {
                    color: #475569;
                    font-family: Rubik;
                    font-size: 11px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 14px;
                }
            }
        }

        .message-item-user {
            .info-box {
                align-items: start;
            }
        }

        .answer {
            display: flex;
            min-height: 96px;
            gap: 16px;
            width: calc(100% - 28px);
            padding: 20px;
            align-items: center;
            border-radius: 8px;
            background: var(--day-carbone-10, #FFF);
            box-shadow: 0px 2px 12px 0px rgba(24, 144, 255, 0.04);
            margin-left: 14px;
            margin-right: 14px;
            margin-top: 8px;

            .file-box {
                width: 30px;
                min-width: 30px;
                height: 30px;
                position: relative;
                overflow: hidden;

                input {
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    z-index: 1;
                    opacity: 0;
                    cursor: pointer;
                    //visibility: hidden;
                }

                .btn__attach {
                    width: 30px;
                    height: 30px;
                    border: none;
                    background-color: inherit;
                    background-image: url('./img/attach_icon.svg');
                    background-position: center;
                    background-repeat: no-repeat;
                }
            }

            textarea {
                width: 100%;
                border: none;
                outline: none;
                resize: none;
                color: var(--text-tertiary, #131517);
                vertical-align: middle;
                /* Основной текст/Подписи */
                font-family: Inter;
                font-size: 13px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;

                &::placeholder {
                    color: var(--text-tertiary, #A1A7B3);
                    /* Основной текст/Подписи */
                    font-family: Inter;
                    font-size: 13px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                }
            }
        }

        .forward-message,
        .add-to-templates {
            display: flex;
            align-items: center;
            gap: 4px;

            .text {
                white-space: nowrap;
                color: #8F99A3;
                font-family: Rubik;
                font-size: 11px;
                font-style: normal;
                font-weight: 400;
                line-height: 12px;
            }

            &__btn {
                //    background-image: url('./img/add_icon.svg');
                background-size: 18px;
                width: 18px;
                height: 18px;
                border: none;
                background-color: inherit;
            }

            &__tooltip {
                opacity: 0;
                visibility: hidden;
                display: inline-flex;
                width: fit-content;
                padding: 6px;
                border-radius: 2px;
                border: 1px solid #C5CCD3;
                background: #F1F5F9;
                color: var(--primary-primary-700, #004280);
                text-align: center;
                font-family: Inter;
                font-size: 11px;
                font-style: normal;
                font-weight: 500;
                line-height: 12px;
                max-width: 260px;
                width: max-content;
            }
        }

        .forward-message {
            &__btn {
                ///     background-image: url('./img/forward_icon.svg');
            }

            &:hover {
                .forward-message__tooltip {
                    opacity: 1;
                    visibility: visible;
                    transition: 0.3s;
                }
            }
        }

    }

    &__information {
        display: flex;
        flex-direction: column;
        gap: 12px;
        width: 100%;
        border-radius: 8px;
        max-width: calc(180px*2 + 12px);

        .parametr-item {
            display: flex;
            gap: 16px;

            .parametr-name {
                width: calc(50% - 6px);
                color: #8F99A3;
                font-family: Inter;
                font-size: 13px;
                font-style: normal;
                font-weight: 400;
                line-height: 18px
            }

            .parametr-blue {
                color: #1890FF;
                font-family: Inter;
                font-size: 13px;
                font-style: normal;
                font-weight: 400;
                line-height: 18px;
            }

            .parametr-text {
                color: #020617;
                font-family: Inter;
                font-size: 13px;
                font-style: normal;
                font-weight: 400;
                line-height: 18px;
            }

            .parametr-rating {
                display: flex;
                align-items: center;
                gap: 2px;
            }

            .parametr-status {
                display: flex;
                height: 18px;
                padding: 4px 24px;
                justify-content: center;
                align-items: center;
                border-radius: 12px;
                background: #FFF4E4;
                color: #E99518;
                text-align: center;
                font-family: PT-Root;
                font-size: 13px;
                font-style: normal;
                font-weight: 400;
                line-height: 14px;
                white-space: nowrap;
            }

            .parametr-imgs {
                display: flex;
                align-items: center;
                gap: 24px;
                flex-wrap: wrap;

                .icon-container {
                    width: 52px;
                    max-width: 52px;
                    min-width: 52px;
                    border-radius: 8px;

                    img {
                        width: 100%;
                        object-fit: cover;
                    }
                }
            }

            .parametr-likes {
                display: flex;
                gap: 10px;
                align-items: center;

                .like-box {
                    display: flex;
                    align-items: center;
                    gap: 4px;
                }


            }

            .parametr-copy {
                display: flex;
                gap: 10px;
                align-items: center;

                .btn-copy {
                    background-image: url('./img/copy.svg');
                    width: 16px;
                    height: 16px;
                    min-width: 16px;
                    border: none;
                    background-color: inherit;
                    background-repeat: no-repeat;
                    background-position: center;
                }
            }


        }

        .question {
            display: flex;
            gap: 14px;

            &__content {
                display: flex;
                flex-direction: column;
                gap: 8px;
                width: 100%;

                .title {
                    color: var(--main-menu-primary-text, #020617);
                    font-family: Inter;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                }

                p {
                    color: var(--main-menu-teritary-text, #94A3B8);
                    font-family: Inter;
                    font-size: 13px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;

                    span {
                        color: var(--main-menu-secondary-text, #475569);
                        font-family: Inter;
                        font-size: 13px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                    }
                }
            }

            .icon-container {
                width: 52px;
                max-width: 52px;
                min-width: 52px;
                border-radius: 8px;

                img {
                    width: 100%;
                    object-fit: cover;
                }
            }
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        padding: 20px 24px;
        height: auto;
        gap: 12px;
        width: 100%;

        .review-content {
            display: flex;
            flex-direction: column;
            gap: 4px;
            width: 100%;

            &__header {
                display: flex;
                width: 100%;
                gap: 16px;
                width: 100%;
                justify-content: space-between;
                align-items: center;

                .title {
                    color: #020617;
                    font-family: Inter;
                    font-size: 13px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 20px;
                }

                .text_grey {
                    color: #8F99A3;
                    font-family: Inter;
                    font-size: 10px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    letter-spacing: -0.22px;
                }
            }

            &__content {
                display: flex;
                height: 80px;
                padding: 8px 16px;
                align-items: flex-start;
                gap: 16px;
                border-radius: 16px;
                border: 1px solid #E0E7F2;
                background: #F5F7F8;
                min-height: 80px;
                max-height: 200px;
                color: #53616E;
                font-family: Inter;
                font-size: 13px;
                font-style: normal;
                font-weight: 400;
                line-height: 18px;
                overflow: auto;
                height: auto;

                &::-webkit-scrollbar {
                    display: none;
                }
            }
        }
    }

    .answer {
        display: flex;
        min-height: 96px;
        gap: 16px;
        width: calc(100% - 28px);
        padding: 20px;
        align-items: center;
        border-radius: 8px;
        background: var(--day-carbone-10, #FFF);
        box-shadow: 0px 2px 12px 0px rgba(24, 144, 255, 0.04);
        margin-left: 14px;
        margin-right: 14px;
        margin-top: 8px;

        .file-box {
            width: 30px;
            min-width: 30px;
            height: 30px;
            position: relative;
            overflow: hidden;

            input {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                z-index: 1;
                opacity: 0;
                cursor: pointer;
                //visibility: hidden;
            }

            .btn__attach {
                width: 30px;
                height: 30px;
                border: none;
                background-color: inherit;
                background-image: url('./img/attach_icon.svg');
                background-position: center;
                background-repeat: no-repeat;
            }
        }

        textarea {
            width: 100%;
            min-height: 56px;
            padding-top: 13px;
            padding-bottom: 13px;
            border: none;
            outline: none;
            resize: none;
            color: var(--text-tertiary, #131517);
            /* Основной текст/Подписи */
            font-family: Inter;
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;

            &::placeholder {
                color: var(--text-tertiary, #A1A7B3);
                /* Основной текст/Подписи */
                font-family: Inter;
                font-size: 13px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }
        }

        .btn__blue {
            min-width: 156px;
            max-width: 156px;
            min-height: 56px;
        }
    }

    .answer-tooltip {
        width: calc(100% - 28px);
        padding-left: 14px;
        padding-right: 14px;
        color: var(--main-menu-teritary-text, #94A3B8);
        font-family: Inter;
        font-size: 10px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-top: 24px;


        &__link {
            color: #1890FF;
        }
    }

    .btn-close {
        background-image: url('./img/close.svg');
        background-repeat: no-repeat;
        background-position: center;
        width: 20px;
        height: 20px;
        border: none;
        background-color: inherit;
        top: 36px;
        right: 33px;
        position: static;
    }
}

