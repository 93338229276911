.financial-analysis-inside-content {
    display: flex;
    flex-direction: column;
    gap: 22px;
    width: 100%;
    height: 100%;

    .inside-box {
        display: flex;
        width: 100%;
        gap: 20px;
    }
}