.mapping-inside-content {
    display: flex;
    flex-direction: column;
    gap: 24px;
    align-items: center;
    width: 100%;
    height: 100%;

    .btn-group {
        display: flex;
        flex-wrap: wrap;
        gap: 8px;
    }
}