.autorization {

    &__input-field {
        width: 100%;
       height: 100%;
        font-family: 'Inter';
        font-size: 14px;
        padding: 16px 20px;
        margin-top: 20px;
        border: 1px solid #F0F0F0;
        border-radius: 16px;

        &::placeholder {
            font-size: 14px;
            font-family: "Inter";
            color: #020617;
        }

        &_err {
            border: 1px solid #FF4D4F;
            &::after {
                content: "Обязательное поле";
                background-color: #FF4D4F;
                color: #9c2424;
                z-index: 100;
                display: inline-block;
                position: absolute;
                width: 100px;
                height: 16px;
                font-family: "Inter";
                font-size: 12px;
            }
        }
    }

    .err-box {
        margin-top: 24px;
        display: flex;
        flex-direction: column;
        gap: 8px;
        width: 100%;
        padding-left: 8px;

        .text_red {
            margin: 0;
        }
    }
}

.right-header-box {
    display: flex;
    align-items: center;
    width: 354px;
    gap: 16px;

    & .text {
        white-space: nowrap;
    }
}

.blue {
    color: #1890FF;
}

.input-with-btn {
    display: flex;
}

.text {
    font-size: 14px;
    font-family: 'Inter';
    user-select: none;

    &_gap{
        display: flex;
        gap: .5em;
    }

    &_blue {
        display: inline-block;
        color: #1890FF;
    }

    &_red {
        color: #FF4D4F;
    }
}

.title {
    font-family: "Inter";
    font-size: 20px;
    font-weight: 400;
}

.bottom-box-registration {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    gap: 24px;

    .btn__blue {
        margin: 0;
        width: 100%;
        max-width: 100%;
        min-height: 40px;
        min-width: 100%;
        border-radius: 8px;
        font-size: 14px;
    }
}