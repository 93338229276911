.сard-metrics-ver-2 {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 12px;
    width: 400px;
    min-width: 400px;
    border-radius: 12px;
    background: #FFF;
    padding: 16px;
    border-radius: 10px;
    background: #FFF;

    &__title {
        color: #000;
        font-family: Inter;
        font-size: 17px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
    }

    &__data {
        display: flex;
        width: 100%;
        gap: 13px;
        justify-content: space-between;
        align-items: center;
    }

    &__data-prev {
        color: #959595;
        font-family: Inter;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
    }

    &__value {
        display: flex;
        gap: 8px;
        color: #000;
        font-family: Inter;
        font-size: 31px;
        font-style: normal;
        font-weight: 600;
        line-height: 39px;
    }
}