.sidebar-inside,
.sidebar-inside_active {
    height: 100%;
    position: sticky;
    top: 20px;
    // max-height: 720px;
    min-width: 220px;
    max-width: 220px;
    background-color: #F6F7F6;
    padding: 0px;
    overflow: hidden;
    border-radius: 8px 8px 0px 0px;
    border: 1px solid #EDEFFB;
    background: #FFF;
    transition: all 0.5s;
    height: fit-content;

    &__icon-drop {
        transform: rotate(180deg);
    }

    &_active {
        max-height: 0px;
        min-width: 0px;
        max-width: 0px;
        padding: 0px;
        overflow: hidden;
    }

    &__list-item,
    &__list-item_active {
        padding: 10px 24px;
        border-bottom: 1px solid #EDEFFB;
        cursor: pointer;
        width: 100%;
        transition: background-color 0.1s;
        color: #636D65;
        font-family: Rubik;
        font-size: 11px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;

        &:hover {
            transition: 0.5s;
            color: #00B45E;
            transition: background-color 0.3s;
        }
    }

    &__list-item_active {
        transition: 0.5s;
        color: #00B45E;
    }


    &__link-box {
        display: flex;
        padding: 10px 14px;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #EDEFFB;



        .sidebar-inside__list-item,
        .sidebar-inside__list-item_active {
            color: #636D65;
            font-family: Rubik;
            font-size: 11px;
            font-style: normal;
            font-weight: 500;
        }
    }

    &__main-box,
    &__main-box_white {
        display: flex;
        align-items: start;
        flex-direction: column;
        gap: 16px;
        height: auto;
        width: calc(100% - 240px - 16px);
        min-height: 720px;

        &_white {
            background-color: white;
            padding: 16px;
        }
    }

    &__list {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        overflow: hidden;
        height: 100%;
        max-height: 1000px;
        transition: max-height 1s;
    }

    &__link-box_grey {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 14px;
        border-bottom: 1px solid #EDEFFB;

        &:hover {
            color: #00B45E;
        }
    }

    &__list_hide {
        display: flex;
        flex-direction: column;
        overflow: hidden;
        height: 100%;
        max-height: 0;
        transition: height 1s;
        -o-transition: height 1s;
        -ms-transition: height 1s;
        -webkit-transition: height 1s;
        -moz-transition: height 1s;
    }

    &__link,
    &__link_active,
    &__link_grey {
        display: flex;
        justify-content: space-between;
        width: 100%;
        align-items: center;
        color: #636D65;
        font-family: Rubik;
        font-size: 11px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
        text-align: left;

        &_active {
            color: #00B45E;
        }

        &_grey {
            color: #888888;
        }
    }
}