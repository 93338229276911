
.green-btn {
    background-color: #22C55E;

    &:hover {
        background: #16A34A;
        transition: 0.5s;
    }

    &[disabled] {
        position: relative;
        cursor: not-allowed;
        background-color: #BBF7D0;
        color: #ffffff;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;

        &:hover {
            box-shadow: none;
            background-color: #BBF7D0;
            box-shadow: none;
        }

        &:active {
            border: none;
        }

    }
}

.blue-btn {
    background-color: #1890FF;
    box-shadow: 2px 4px 10px 0px rgba(24, 144, 255, 0.25);

    &:hover {
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
        background-color: #005DB2;
        transition: 0.5s;
        box-shadow: none;
    }

    &:active {
        background-color: #1890FF;
        border: 2px solid #005DB2;
    }

    &[disabled] {
        position: relative;
        cursor: not-allowed;
        background-color: #B2DAFF;
        color: #ffffff;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;

        &:hover {
            box-shadow: none;
            background-color: #B2DAFF;
            box-shadow: none;
        }

        &:active {
            border: none;
        }

    }
}

.orange-btn {
    background-color: #FB923C;

    &:hover {
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
        background-color: #F97316;
        transition: 0.5s;
        box-shadow: none;
    }

    &:active {
        background-color: #FB923C;
        border: 2px solid #F97316;
    }

    &[disabled] {
        position: relative;
        cursor: not-allowed;
        background-color: #FFEDD5;
        color: #ffffff;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;

        &:hover {
            box-shadow: none;
            background-color: #FFEDD5;
            box-shadow: none;
        }

        &:active {
            border: none;
        }

    }
}

.white-blue-btn {
    color: #1890FF;
    background: white;
    border: 1px solid #1890FF;

    &:hover {
        background-color: #E0E7F2;
        border: 1px solid #1890FF;
        transition: all 0.5s;
    }

    &:active {
        transition: all 0.5s;
        border: 2px solid #1890FF;
    }

    &[disabled] {
        position: relative;
        cursor: not-allowed;
        background-color: #E1E6EA;
        border: none;
        color: #A9B3BC;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;

        &:hover {
            box-shadow: none;
            border: none;
            background-color: #E1E6EA;
            box-shadow: none;
        }

        &:active {
            border: none;
        }

    }
}

.red-btn {
    background-color: #F87171;
    color: white;

    &:hover {
        background-color: #EF4444;
        transition: 0.5s;
    }

    &:active {
        background-color: #F87171;
        border: 2px solid #EF4444;
        transition: 0.5s;
    }

    &[disabled] {
        position: relative;
        cursor: not-allowed;
        background-color: #FEE2E2;
        color: #ffffff;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;

        &:hover {
            box-shadow: none;
            background-color: #FEE2E2;
            box-shadow: none;
        }

        &:active {
            border: none;
        }

    }
}

.grey-btn {
    color: #53616E;
    background: #E1E6EA;
    border: 1px solid #E1E6EA;

    &:hover {
        background-color: #C5CCD3;
        border: 1px solid #E1E6EA;
        transition: all 0.5s;
    }

    &:active {
        transition: all 0.5s;
        border: 2px solid #64748B;
    }

    &[disabled] {
        position: relative;
        cursor: not-allowed;
        background-color: #F1F5F9;
        border: none;
        color: #94A3B8;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;

        &:hover {
            box-shadow: none;
            border: none;
            background-color: #F1F5F9;
            box-shadow: none;
        }

        &:active {
            border: none;
        }

    }
}