.settings {

    &__content {
        display: flex;
        flex-direction: column;
        gap: 18px;
        width: 100%;
        height: auto;
        margin-left: auto;
        margin-right: auto;
        padding-left: 20px;
        padding-right: 20px;

        .title-box {
            cursor: pointer;
            max-width: 240px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            padding-right: 16px;
            padding-left: 24px;
            gap: 8px;
        }
    }

    &__link {
        text-align: left;
        font-weight: 400;
    }

    &__link-box {
        & .settings__link {
            font-weight: 500;
            color: #020617;
            margin: 8px;
        }

        .icon-drop,
        .icon-drop_active {
            width: 9px;
            height: 6px;
        }
    }

    &__main-box,
    &__main-box_white {
        display: flex;
        flex-direction: column;
        gap: 16px;
        height: auto;
        width: calc(100% - 240px - 16px);
        min-height: 720px;

        &_white {
            background-color: white;
            padding: 16px;

            .settings_connection_header {
                background-color: #fff;
            }
        }
    }

    &__list-item,
    &__list-item_active {
        padding: 8px;
        padding-left: 16px;
        cursor: pointer;
        width: 100%;
        transition: background-color 0.1s;
        border-radius: 8px;
        color: #475569;

        &_active {
            background-color: #E6F7FF;
            color: #1890FF;
        }

        &:hover {
            background-color: #E6F7FF;
            color: #1890FF;
            transition: background-color 0.3s;
        }
    }

    &__list {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        & .settings__link {
            font-weight: 400;
        }
    }
}

.flex {
    display: flex;
    gap: 16px;
}

.title-and-nav-box {
    display: flex;
    flex-direction: column;
    gap: 16px;
    background-color: inherit;
    max-width: 240px;

    .title {
        display: inline-block;
        width: 100%;
        padding-left: 24px;
    }
}