@font-face {
    font-family: Inter;
    src: url("../public/font/Inter-Regular.ttf");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: Inter;
    src: url("../public/font/Inter-Medium.otf");
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: Circe Rounded;
    src: url("../public/font/CirceRounded-Regular.woff");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: Montserrat;
    src: url("../public/font/Montserrat-Regular.ttf");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: Inter;
    src: url("../public/font/Inter-Light.otf");
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: Inter;
    src: url("../public/font/Inter-Bold.otf");
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: PT-Root;
    src: url("../public/font/PT-Root-UI_Regular.ttf");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: IBMPlexSans;
    src: url("../public/font/IBMPlexSans-Regular.ttf");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: IBMPlexSans;
    src: url("../public/font/IBMPlexSans-Medium.ttf");
    font-weight: 500;
    font-style: normal;
}

/* Rubik */

@font-face {
    font-family: Rubik;
    src: url("../public/font/Rubik/Rubik-Regular.ttf");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: Rubik;
    src: url("../public/font/Rubik/Rubik-Medium.ttf");
    font-weight: 500;
    font-style: normal;
}

/* Nunito Sans */

@font-face {
    font-family: NunitoSans;
    src: url("../public/font/Nunito_Sans/NunitoSans-Regular.ttf");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: NunitoSans;
    src: url("../public/font/Nunito_Sans/NunitoSans-SemiBold.ttf");
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: NunitoSans;
    src: url("../public/font/Nunito_Sans/NunitoSans-Bold.ttf");
    font-weight: 800;
    font-style: normal;
}




* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

a {
    text-decoration: none;
    color: inherit;
}

li {
    list-style: none;
}

body {
    background-color: #F5F5F5;
    overflow-x: hidden;
    overflow-x: hidden;
}

html, body {
    width: 100%;
    height: 100%;
    margin: 0;
    background-color: #F5F5F5;
}

.main {
    display: flex;
    width: 100%;
    height: auto;
    
}

#root {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}

.content {
    display: flex;
    flex-shrink: 1;
    width: 100%;
    min-height: calc(100vh);
    flex-direction: column;
    background-color: #F6F7F6;
    padding-bottom: 100px;
    /* overflow: hidden; */
    /* padding-left: 50px; */
    position: relative;
   /*страница абсолют position: relative; */
}

.content-area {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    max-width: 100%;
    padding: 20px 20px;
}

.b24-form {
    position: absolute;
    right: 2em;
    bottom: 1em;
}

.absolute-content{
    position: absolute;
    z-index: 2;
    top: 0;
    right: 0;
    left: 0;
}

.drop-data-component{
    z-index: 10;
}
/* Общие классы */

.title_main {
    color: #020617;
    font-family: Rubik;
    font-size: 28px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: -0.56px;
    margin-bottom: 12px;
}