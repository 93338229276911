@import '../../common/styles/mixins.scss';
@import '../../common/styles/svg_img.scss';

body {

    &:has(.blackout) {
        overflow: hidden;
    }

    .blackout, .blackout_hide {
        display: flex;
        width: 100vw;
        height: 100vh;
        min-height: 100vh;
        z-index: 10;
        position: fixed;
        overflow: auto;
        align-items: center;
        justify-content: center;
        background-color: rgb(0, 0, 0, 0.3);
        padding: 20px;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;

        &_hide {
            z-index: 10000;
        }

        &::before {
            content: "";
            display: none;
            //     top: 0;
            //     left: 0;
            //     right: 0;
            //     bottom: 0;
            //     position: absolute;
            //     z-index: 1000;
            //     opacity: 0.6;
        }
    }
}


.close_modal_btn {
    position: absolute;
    display: block;
    width: 1em;
    height: 2px;
    background-color: #020617;
    right: 1.5em;
    top: 1.5em;
    transform: rotate(45deg);
    cursor: pointer;

    &::before {
        content: "";
        display: block;
        width: 1em;
        height: 2px;
        background-color: #020617;
        transform: rotate(90deg);
    }
}

.back {
    width: 16px;
    height: 16px;
    border: none;
    cursor: pointer;
    background-color: inherit;
    @include optimizeSafariSvg(url($back));
    display: none;

    &.active {
        display: block;
    }
}


.modal__group-btn {
    display: flex;
    gap: 32px;
    align-items: center;
    position: absolute;
    top: 45px;
    right: 45px;
}

.info,
.close {
    position: relative;
    min-width: 16px;
    min-height: 16px;
    width: 16px;
    height: 16px;
    border: none;
    cursor: pointer;
    background-color: inherit;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 16px;
}

.info {
    display: none;
    @include optimizeSafariSvg(url($info));

    &.active {
        display: block;
    }
}

.close {
    @include optimizeSafariSvg(url($close));
}

.loader-beat {
    position: absolute;
    top: calc(50% - 8px);
    left: calc(50% - 30px);
    z-index: 2001;
}

.social-media-img {
    width: 25px;
    height: 25px;
}