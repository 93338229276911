.modal-new-scenario {
    position: absolute;
    max-width: 762px;
    width: 100%;
    height: 100vh;
    top: 0;
    right: 0;
    bottom: 0;
    border-radius: 0 8px;
    background: #FFF;

    &__header {
        display: flex;
        width: 100%;
        gap: 24px;
        justify-content: space-between;
        width: 100%;
        padding: 16px 48px;
        border-bottom: 2px solid var(--Stroke-Primary, #EDEFFB);
        background: #F8F9FE;

        .title_main {
            white-space: nowrap;
            color: #020617;
            font-family: Rubik;
            font-size: 26px;
            font-style: normal;
            font-weight: 500;
            line-height: 32px;
        }
    }

    &__label {
        display: flex;
        gap: 8px;
        align-items: center;

        span {
            white-space: nowrap;
            color: #475569;
            font-family: NunitoSans;
            font-size: 15px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
        }
    }

    &__btn-box {
        padding: 16px 48px;
        display: flex;
        align-items: end;
    }

    &__input-box {
        display: flex;
        align-items: center;
        gap: 14px;

        span {
            color: #020617;
            font-family: Inter;
            font-size: 15px;
            font-style: normal;
            font-weight: 400;
            line-height: 12px;
        }

        input {
            display: flex;
            width: 57px;
            height: 39px;
            padding: 4px;
            justify-content: center;
            align-items: center;
            gap: 8px;
            border-radius: 4px;
            background: #F1F5F9;
            border: none;
            height: 40px;
            -webkit-appearance: none;
            margin: 0;
            color: #020617;
            font-family: Inter;
            font-size: 15px;
            font-style: normal;
            font-weight: 600;
            line-height: 12px;
            text-align: center;


            &::-webkit-inner-spin-button {
                -webkit-appearance: none;
            }

            &:focus {
                outline: none;
            }
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        gap: 24px;
        width: 100%;
        height: calc(100% - 170px);
        padding: 32px 48px;
        overflow: auto;

        .filter-group {
            display: flex;
            width: 100%;
            gap: 12px;
            flex-wrap: wrap
        }

        .points {
            display: flex;
            flex-direction: column;
            gap: 24px;
            width: 100%;

            .bottom-content {
                display: flex;
                justify-content: space-between;
                gap: 8px;
                align-items: start;

                .filter-group {
                    width: auto;
                }
            }

            .top-content {
                display: flex;
                gap: 6px;
                align-items: start;
                justify-content: space-between;
                width: 100%;

                .modal-new-scenario__text {
                    width: 40%;
                }

                .selector_wrapper{
                    width: 60%;
                    max-width: 355px;
                }
            }

            &__item {
                display: flex;
                flex-direction: column;
                gap: 26px;
                width: 100%;



                .text {
                    color: #475569;
                    font-family: Rubik;
                    font-size: 15px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 20px;
                }
            }
        }
    }
}