.analysis-platform-by-brand {
    display: flex;
    width: 100%;
    flex-direction: column;
    max-width: 100%;
    gap: 24px;

    &__content {
        display: flex;
        width: 100%;
        flex-direction: column;
        max-width: 100%;
        gap: 24px;
        max-width: calc(1440px - 120px);
        margin-left: auto;
        margin-right: auto;
        padding-left: 20px;
        padding-right: 20px;
    }

    .range-group-btn-and-range-box {
        display: flex;
        align-items: center;
        gap: 12px;

        .selector_wrapper {
            max-width: 124px;
            min-width: 124px;

            .css-13cymwt-control,
            .css-t3ipsp-control {
                background-color: #F1F5F9;

                &:hover {
                    background-color: #E1E6EA;
                }
            }
        }

        .drop-box {
            display: flex;
            align-items: center;
            gap: 16px;
        }
    }

    .charts-container {
        display: flex;
        width: 100%;
        height: 100%;
        gap: 8px;
    }

    .metrics-items {
        display: flex;
        gap: 8px;
        flex-wrap: wrap;
        margin-top: 12px;
    }

    .btn__blue {
        &:hover {
            background-color: #0E86D7;
            transition: 0.5s;
            box-shadow: none;
        }
    }

    .tooltip-blue {
        margin-bottom: 16px;
    }

    .analysis-platform-by-brand {
        padding: 0;
    }

    .text_blue-gray {
        color: #93B4D1;
        font-size: 16px;
        margin-bottom: 20px;
    }

    .table-box-component {
        align-items: flex-start;
    }

    .top-panel {
        display: flex;
        width: 100%;
        align-items: center;
        gap: 24px;

        .checkbox-container {
            display: flex;
            gap: 8px;
            align-items: center;
    
            .text_label,
            .text_label_disabled {
                color: #020617;
                font-family: Rubik;
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
                line-height: 16px;
    
                &_disabled {
                    color: #D3DEE7;
                }
            }
        }

        .btn-group {
            display: flex;
            align-items: center;
            gap: 12px;
        }
    }

    .medium-panel {
        display: flex;
        width: 100%;
        gap: 30px;
        margin-top: 20px;

        &:nth-child(2) {
            justify-content: flex-end;
        }

        .alphabetical-search {
            margin-top: 0;
        }
    }

    .from-to-component-group {
        display: flex;
        gap: 20px;
        margin-top: 40px;
        flex-wrap: wrap;
    }



    .toolbar-top {
        display: flex;
        align-items: center;
        gap: 27px;

        .btn__drop {
            background-position: center;
            background-repeat: no-repeat;
            background-image: url('./img/drop-grey-left.svg');
            // transform: scale(-1, 1);
            // transform: rotate(180deg);
            background-color: inherit;
            cursor: pointer;
            z-index: 0;

            &_active {}
        }

        .title {
            font-size: 32px;
            font-weight: 500;
        }
    }

    .toolbar-medium {
        display: flex;
        width: 100%;
        justify-content: space-between;
        gap: 16px;
        flex-wrap: wrap;
        align-items: flex-end;

        .range-group-btn-and-range-box {
            display: flex;
            align-items: center;
            gap: 30px;

            .selector_wrapper {
                max-width: 124px;
                min-width: 124px;

                .css-13cymwt-control,
                .css-t3ipsp-control {
                    background-color: #F1F5F9;

                    &:hover {
                        background-color: #E1E6EA;
                    }
                }
            }
        }
    }

    .tab-box {
        display: flex;
        width: auto;
        row-gap: 12px;
        flex-wrap: wrap;

        .btn__tab,
        .btn__tab_active {
            cursor: pointer;
            height: 34px;
            min-width: 130px;
            width: auto;
            font-size: 16px;
            font-weight: 500;
            background-color: inherit;
            border: none;
            padding-right: 20px;
            padding-left: 20px;
            white-space: nowrap;
            line-height: 20px;
            border-bottom: 2px solid #E0E7F2;

            &:hover {
                transition: 0.5s;
                border-bottom: 2px solid #1890FF;
                color: #1890FF;
            }

            &_active {
                border-bottom: 2px solid #1890FF;
                color: #1890FF;
            }
        }
    }

    .charts-and-metrics,
    .charts-and-metrics_hide {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 40px;
        justify-content: space-between;
        background-color: white;
        background-color: white;
        border-radius: 8px;
        padding: 20px;
        padding-right: 88px;
        margin-top: 12px;
        position: relative;
        height: -webkit-fill-available;
        min-height: calc(24px + 40px);
        max-height: 100%;
        overflow: hidden;
        transition: max-height 1s;

        .right-content {
            display: flex;
            flex-direction: column;
            gap: 28px;
            max-width: 506px;
            width: 100%;
            flex-shrink: 0;
            flex-grow: 0;
        }

        .title {
            color: #888888;
            font-size: 16px;
            margin-top: 4px;
        }

        .inside-content {
            display: flex;
            width: 100%;
            overflow: hidden;
            gap: 40px;
            flex-wrap: wrap;

            .metrics-box {
                display: flex;
                align-items: start;
                height: min-content;
                flex-wrap: wrap;
                column-gap: 10px;
                row-gap: 15px;
                max-width: calc(248px*2 + 10px);
                width: 100%;
                min-height: 30px;
            }

            .statistic {
                display: flex;
                align-items: start;
                height: min-content;
                flex-wrap: wrap;
                column-gap: 10px;
                row-gap: 15px;
                max-width: calc(248px*2 + 10px);
                width: 100%;
                min-height: 30px;

                &__item {
                    display: flex;
                    flex-direction: column;
                    gap: 8px;
                    min-width: 248px;
                    padding-left: 12px;
                    padding-right: 12px;

                    .text_grey {
                        font-size: 14px;
                    }

                    .text {
                        font-weight: 500;
                        font-size: 16px;
                    }

                    .text_burgundy {
                        font-weight: 500;
                        color: #A53100;
                        font-size: 16px;
                    }
                }
            }
        }

        .line_chart {
            flex-shrink: 1;
            padding: 0;
            width: 100%;
            max-height: 250px;
            min-height: 250px;
        }

     

        &_hide {
            max-height: 72px;
            height: 100%;
        }

        .static-box {
            display: flex;
            align-items: center;
            gap: 10px;
            position: absolute;
            top: 20px;
            right: 30px;

            .btn__drop,
            .btn__drop_active {
                height: 32px;
                width: 32px;
                border-radius: 50%;
                background-image: url('./img/drop_grey.svg');
                background-color: #E6F7FF;
                cursor: pointer;
                z-index: 0;
                border: none;
                background-size: 14px;
                background-repeat: no-repeat;
                background-position: center;
            }
        }
    }
}