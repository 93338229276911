.employees {
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;
    height: 100%;
    border-radius: 16px;
    
    &__title {
        color: #0c2037;
        font-family: Rubik;
        font-size: 28px;
        font-style: normal;
        font-weight: 500;
        letter-spacing: -.56px;
        line-height: 32px;
        margin-bottom: 12px;
    }

    .employees-list {
        &__item {
            display: flex;
            min-width: 350px;
            width: 100%;
            align-items: center;
            margin-top: 10px;
            border-bottom: 1px dashed grey;

            .name-box {
                display: flex;
                width: 100%;
                flex-wrap: wrap;
                max-width: calc(100% - 30px);
                justify-content: space-between;
                gap: 16px;
            }

            .btn-box {
                width: 30px;
                display: flex;
                align-items: center;
                justify-content: end;
            }
        }
    }
}