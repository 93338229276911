.modal-analysis-platform-by-sale-type {
    display: flex;
    flex-direction: column;
    gap: 24px;
    position: relative;
    z-index: 1000;
    display: flex;
    max-width: 100%;
    max-height: calc(100vh - 40px);
    overflow: auto;
    width: 100%;
    min-width: 360px;
    margin-left: auto;
    margin-right: auto;
    padding: 32px;
    flex-direction: column;
    align-items: center;
    border-radius: 8px;
    background: var(--white, #FFF);
    box-shadow: 0px 12px 32px 0px rgba(0, 0, 0, 0.12);

    .analysis-platform-by-sale-type {
        display: flex;
        flex-direction: column;
        gap: 24px;
        width: 100%;
        max-width: 100%;
    }
}