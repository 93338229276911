.sceleton-doughnut-chart {
    display: flex;
    width: 100%;
    padding: 20px;
    min-height: 220px;
    background: #EAEAEA;
    justify-content: center;
    overflow: hidden;
    border-radius: 8px;
    height: 100%;

    &__content {
        display: flex;
        align-items: flex-start;
        flex-direction: row !important;
        gap: 60px;
        width: 100%;
    }

    &__col {
        display: flex;
        width: calc(100% - 160px - 60px) ;
        flex-direction: column;
    }
}