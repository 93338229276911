.options-executor {
    display: flex;
    align-items: center;
    width: auto;
    gap: 16px;
    padding: 4px 16px;


    img {
        width: 20px;
        height: 20px;
    }

    .text-box {
        display: flex;
        flex-direction: column;

        .text {
            font-size: 11px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            max-width: 180px;
            color: #636D65;
            font-family: NunitoSans;
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;
        }

        .text_grey {
            font-size: 10px;
            color: grey;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            max-width: 180px;
        }
    }

    &:hover {
        background-color: #E6F7FF;
        transition: background-color 0.5s;
    }
}

.executor-placeholder {
    border-radius: 50%;
    background-color: white;
    color: #fff;
    border: 1px solid #fff;
    text-align: center;
    font-family: NunitoSans;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px;
    min-height: 32px;
    width: 32px;
    height: 32px;
    min-width: 32px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 13px;
    userSelect: none;
    cursor: pointer;
    
    // &__text {
    //     line-height: 1 !important;
    // }

    &__item {
        display: flex;
        align-items: center;
        gap: 8px;
        min-width: 220px;
        max-width: 220px;
        overflow: hidden;
    }

    &__name {
        display: -webkit-box;
        -webkit-line-clamp: 1; // количество строк
        -webkit-box-orient: vertical;
        word-break: break-word;
        overflow: hidden;
        hyphens: auto;
        font-size: 13px;
        font-style: normal;
        font-weight: 600;
        line-height: 18px
    }

    &__email {
        display: -webkit-box;
        -webkit-line-clamp: 1; // количество строк
        -webkit-box-orient: vertical;
        word-break: break-word;
        overflow: hidden;
        hyphens: auto;
        color: #636D65;
        font-size: 11px;
        font-style: normal;
        font-weight: 300;
        line-height: 14px;
    }

    &__info {
        display: flex;
        flex-direction: column;
        gap: 6px;
        max-width: calc(220px - 48px - 8px);
        overflow: hidden;
    }
}