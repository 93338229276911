.react-datepicker {
    border-radius: 16px;
    overflow: hidden;
    border-right: 1px solid rgba(0, 0, 0, 0.25);
}

.react-datepicker__header  {
    background-color: #F0F0F0;
    position: static;
}

.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range),
.react-datepicker__month-text--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range),
.react-datepicker__quarter-text--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range),
.react-datepicker__year-text--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range) {
    background-color: #E6F7FF;
    border-radius: 16px;
    color: #1890FF;

    &:hover {
        background-color: #E6F7FF;
        border-radius: 16px;
        color: #1890FF;
    }
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-,
.react-datepicker__day--keyboard-selected {
    background-color: #E6F7FF;
    border-radius: 16px;
    color: #1890FF;

    &:hover {
        background-color: #E6F7FF;
        border-radius: 16px;
        color: #1890FF;
    }
}

.react-datepicker__day {
    &:hover {
        background-color: #E6F7FF;
        border-radius: 16px;
        color: #1890FF;
    }
}

.react-datepicker__navigation {
    z-index: 0;
}
