.product-card-extended {
    .drop-container {
        .left-content {
            width: auto !important;
        }
    }
    .product-improvement-inside-content {
        display: flex;
        flex-direction: column;
        gap: 24px;
        width: 100%;
        height: 100%;

        .toolbar-top {
            display: flex;
            width: 100%;
            align-items: center;
            flex-wrap: wrap;
            justify-content: space-between;
            gap: 24px;

            .btn-group {
                display: flex;
                align-items: center;
                justify-content: space-between;
                gap: 12px;
                flex-wrap: wrap;

                .content-btn {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 8px;
                }
            }
        }

        &__changed-content {
            display: flex;
            gap: 16px;
        }
    }
}