.page-404 {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 60px;
    max-width: 90%;
    margin: auto;

    .title {
        text-align: center;
        color: var(--unnamed, #3B2C37);
        /* Lending/Mon48 */
        font-family: Montserrat;
        font-size: 48px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }

    .title_medium {
        margin-top: 10px;
        color: #111;
        text-align: center;
        font-family: Montserrat;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    img {
        margin-top: 40px;
        max-width: 514px;
        width: 100%;
        min-width: 272px;
    }
}

@media (max-width: 780px) {
    .page-404 {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 60px;
        max-width: 90%;
        margin-left: auto;
        margin-right: auto;

        .title {
            font-size: 16px;
        }

        .title_medium {
            margin-top: 10px;
           font-size: 14px;
        }

        img {
            margin-top: 40px;
            max-width: 514px;
        }
    }
}