.carousel-slick,
.carousel-slick_min {
    max-width: 300px;
    min-width: 300px;

    .slick-initialized {
        // display: flex;
        // align-items: center;
        position: relative;
    }


    .slick-list {
        width: calc(100% - 32px);
        margin-left: auto;
        margin-right: auto;
    }

    .content-img {
        display: flex;
        align-items: center;
        justify-content: center;
        //   width: calc(100% - 32px);
        border-radius: 8px;
        overflow: hidden;
        height: 100%;
        margin-left: auto;
        margin-right: auto;

        .img-box {
            width: calc(100%);
            height: 100%;
            margin-left: auto;
            margin-right: auto;
        }
    }

    .content-img_min {
        @extend .content-img;
        width: 100%;

        .img-box {
            width: calc(100%);
            margin-left: auto;
            margin-right: auto;
        }
    }

    .not_img {
        display: inline-block;
        width: 24px;
        height: 24px;
        margin-left: auto;
        margin-right: auto;
        vertical-align: middle;
    }

    .img-box,
    .img-box_active {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 33%;
        aspect-ratio: 1/1;
        height: 84px;
        border-radius: var(--Base-radius, 8px);
        border: 2px solid #ffffff;
        overflow: hidden;
        cursor: pointer;

        &_active {
            border: 2px solid #95CBFE;
        }

        .img_slide {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &_min {

        max-width: 300px;
        min-width: 300px;

        .slick-arrow {
            display: none;
        }

        .slick-next,
        .slick-prev {
            display: none;
        }


        .img-box,
        .img-box_active {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 344px;
            border-radius: var(--Base-radius, 8px);
            overflow: hidden;
            cursor: pointer;

            &_active {
                border: 2px solid #95CBFE;
            }

            .img_slide {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }

    .slick-next,
    .slick-prev {
        position: absolute;
        align-items: center;
        width: 16px;
        height: 16px;


        &::before {
            content: '';
            display: block;
            opacity: 1;
            width: 16px;
            min-width: 16px;
            height: 16px;
            background-size: 16px;
            background-repeat: no-repeat;
            background-image: url('./img/arrow_left.svg');
        }
    }

    .slick-prev {
        left: 0;
    }

    .slick-next {
        left: calc(100% - 16px);

        &::before {
            background-image: url('./img/arrow_right.svg');
        }
    }
}