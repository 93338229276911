.space-page {
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;
    height: 100%;

    &__title {
        color: #0C2037;
        font-family: Rubik;
        font-size: 26px;
        font-style: normal;
        font-weight: 500;
        line-height: 32px;
        letter-spacing: -0.56px;
    }

    &__header {
        display: flex;
        align-items: center;
        gap: 24px;
        width: 100%;
        justify-content: space-between;
    }

    &__content {
        display: flex;
        flex-wrap: wrap;
        gap: 12px;
        width: 100%;
    }
}

.card-space {
    display: flex;
    flex-direction: column;
    align-items: end;
    width: 240px;
    border-radius: 10px;
    border: 1px solid #ECECEC;
    cursor: pointer;
    background: #FFF;

    &__btn {
        width: 100%;
        text-align: left;
        color: #585858;
        font-family: "Open Sans";
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 140%;
        border: none;
        padding: 6px 10px;
        background-color: inherit;

        &:hover {
            color: #3246FF;
            transition: 0.5s;
        }
    }

    &__header {
        display: flex;
        flex-direction: column;
        gap: 8px;
        align-items: self-start;
        width: 100%;
        padding: 8px 12px;
        border-bottom: 1px solid #EDEFED;
    }

    &__header-panel {
        display: flex;
        width: 100%;
        align-items: center;
        gap: 12px;

        .button-action-drop {
            margin-left: auto;
        }
    }

    &__title {
        overflow: hidden;
        color: #0B110B;
        text-overflow: ellipsis;
        font-family: "Open Sans";
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 140%;
        min-height: 40px;
        display: -webkit-box;
        -webkit-line-clamp: 2; // количество строк
        -webkit-box-orient: vertical;
        overflow: hidden;
        hyphens: auto;
    }

    &__admin-badge, &__user-badge {
        display: flex;
        padding: 4px 6px;
        align-items: center;
        gap: 2px;
        border-radius: 4px;
        border: 1px solid var(--Text-Accent-Green, #00B45E);
        background: #FFF;
        color: var(--Text-Accent-Green, #00B45E);
        font-family: "Open Sans";
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 140%;
    }

    &__user-badge {
        border: 1px solid #0E76D7;
        color: #0E76D7;
    }

    &__content {
        display: flex;
        flex-direction: column;
        width: 100%;
        background: #F4F4FC;

        .sum_executor {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 32px;
            height: 32px;
            border-radius: 50%;
            background: #F1F5F9;
            color: #2E3552;
            text-align: center;
            font-family: NunitoSans;
            font-size: 10px;
            font-style: normal;
            font-weight: 800;
            line-height: 8px;
            margin-left: 8px;
            cursor: pointer;

            span {
                color: rgba(46, 53, 82, 0.60);
            }
        }

        .tooltip-container {
            margin-left: -6px;

            &:nth-child(1) {
                margin-left: 0;
            }
        }
    }
}