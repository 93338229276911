.not-active {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    gap: 40px;
    height: 100%;
    overflow: hidden;
    padding: 24px;

     img {
            display: inline-block;
            max-width: 800px;
            width: 100%;
            min-width: 100px;
            height: auto;
        }

    .title {
        padding: 0;
        max-width: 800px;
        font-weight: 600;
        font-size: 32px;
        color: #888888; 
    }
}