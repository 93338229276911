.modal-create-new-bundle {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    z-index: 1000;
    display: flex;
    max-width: 1350px;
    gap: 24px;
    width: 100%;
    min-width: 360px;
    margin-left: auto;
    margin-right: auto;
    padding: 24px;
    flex-direction: column;
    align-items: center;
    border-radius: 8px;
    background: var(--white, #FFF);
    max-height: calc(100vh - 40px);
    box-shadow: 0px 12px 32px 0px rgba(0, 0, 0, 0.12);

    &__header {
        display: flex;
        width: 100%;
        gap: 24px;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;

        .title {
            color: #0B110B;
            font-family: NunitoSans;
            font-size: 28px;
            font-style: normal;
            font-weight: 400;
            line-height: 32px;
            letter-spacing: -0.56px;
        }
    }

    &__contact {
        width: 100%;
        height: 100%;
        overflow-x: hidden;
        overflow-y: auto;
    }

    .toolbar-top {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 12px;
        width: 100%;
        margin-top: 12px;
    }

}