.selected-colors-component {
    display: flex;
    flex-direction: column;
    gap: 14px;

    &__label {
        color: #0C2037;
        font-family: "Golos Text";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
    }

    &__content {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
    }

    &__color-block,
    &__color-block_active
     {
        display: flex;
        width: 50px;
        min-width: 50px;
        height: 50px;
        align-items: center;
        gap: 10px;
        border-radius: 10px;
        border: 2px solid #F2F4F4;
        cursor: pointer;

        &:hover, &_active {
            border: 2px solid #00B45E;
        }
    }
}