.logo {
    display: flex;
    gap: 10px;
    align-items: center;
    background-color: inherit;

    img {
        width: 120px;
        height: 32px;
    }

}