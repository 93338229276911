.tabs-grey-btn {
    display: flex;
    align-items: center;
    gap: 16px;
    border-radius: 8px;

    &__content {
        display: flex;
        align-items: center;
        border-radius: 8px;
        overflow: hidden;
    }

    .text {
        color: #636D65;
        text-align: center;
        font-family: NunitoSans;
        font-size: 11px;
        font-style: normal;
        font-weight: 600;
        line-height: 14px;
    }

    .count {
        border-radius: 4px;
        background: #E1E6E2;
        padding: 2px 4px;
        color: #636D65;
        text-align: center;
        font-family: NunitoSans;
        font-size: 13px;
        font-style: normal;
        font-weight: 700;
        line-height: 14px;
    }

    .btn,
    .btn_active {
        background-color: inherit;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-left: 12px;
        padding-right: 12px;
        gap: 8px;
        height: 28px;
        white-space: nowrap;
        border: none;
        color: #636D65;
        border: 1px solid #E1E6E2;
        background-color: #E1E6E2;
        text-align: center;
        font-family: NunitoSans;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;

        &:hover,
        &:active,
        &_active {
            transition: 0.5s;
            background-color: #FAFBFA;
            color: #0B110B;
            border: 1px solid #51CA64;

            &:nth-child(1) {
                border-radius: 8px 0 0 8px;
            }

            &:last-child {
                border-radius: 0 8px 8px 0;
            }

            .count {
                color: #0B110B;  
            }
        }

        &_active {
            font-weight: 800;
        }
    }
}