// modal-password-changed
.modal-grey {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    position: relative;
    z-index: 1000;
    margin-left: auto;
    margin-right: auto;
    top: calc(50% - 120px);
    max-width: 640px;
    min-height: 240px;
    width: 100%;
    background-color: #F5F5F5;
    height: auto;
    padding: 40px 40px 16px;
    border-radius: 16px;
}

.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range),
.react-datepicker__month-text--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range),
.react-datepicker__quarter-text--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range),
.react-datepicker__year-text--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range) {
    background-color: #E6F7FF;
    border-radius: 16px;
    color: #1890FF;

    &:hover {
        background-color: #E6F7FF;
        border-radius: 16px;
        color: #1890FF;
    }
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-,
.react-datepicker__day--keyboard-selected {
    background-color: #E6F7FF;
    border-radius: 16px;
    color: #1890FF;

    &:hover {
        background-color: #E6F7FF;
        border-radius: 16px;
        color: #1890FF;
    }
}

.react-datepicker__day {
    &:hover {
        background-color: #E6F7FF;
        border-radius: 16px;
        color: #1890FF;
    }
}

.react-datepicker__navigation {
    z-index: 1;
}