.modal-group-answer {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 32px;
    position: relative;
    z-index: 1000;
    display: flex;
    max-width: 1600px;
    width: 100%;
    min-width: 340px;
    min-height: 600px;
    margin-left: auto;
    margin-right: auto;
    flex-direction: column;
    border-radius: 8px;
    background: #FFF;
    padding: 24px;
    max-height: calc(100vh - 40px);
    box-shadow: 0px 12px 32px 0px rgba(0, 0, 0, 0.12);

    &__header {
        display: flex;
        width: 100%;
        gap: 24px;
        justify-content: space-between;

        .input-dinamyc-placeholder {
            max-width: 300px;
        }
    }

    &__title {
        color: #020617;
        font-family: NunitoSans;
        font-size: 27px;
        font-style: normal;
        font-weight: 600;
        line-height: 38px;
        white-space: nowrap;
    }

    &__content {
        display: flex;
        width: 100%;
        gap: 18px;
    }

    &__content-in-group,
    &__content-available {
        display: flex;
        flex-direction: column;
        gap: 8px;
        width: 100%;   
        overflow: auto;
        max-height: 400px;
    }

    &__search-group {
        display: flex;
        width: 100%;
        gap: 18px;

        .input-dynamic-width-bar {
            max-width: 100%;
            border: 1px solid #EDEFED;
            border-radius: 10px;
            width: 100%;
        }
    }

    .template-item {
        display: flex;
        gap: 8px;
    }
}