.tooltip-container {
    position: relative;

    .tooltip-black {
        opacity: 0;
        visibility: hidden;
        position: absolute;
        width: max-content;
        z-index: 11;
        top: 36px;
        padding: 6px;
        border-radius: 4px;
        background: #264820;
        box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.25);
        color: #FFF;
        font-family: NunitoSans;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 14px; 
        max-width: 200px;
    }

    &:hover {
        .tooltip-black {
            opacity: 1;
            visibility: visible;
            transition: 0.5s;
        }
    }
}