.add-balance-modal {
  max-width: 480px;
  width: 100%;
  height: 304px;
  position: absolute;
  z-index: 1000;
  // top: calc(50%);
  //  left: calc(50%);
  padding: 16px 32px;
  position: relative;
  background-color: white;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__input-field {
    display: inline-block;
    max-width: 240px;
    text-align: center;
    color: #020617;
    font-size: 14px;
    border-radius: 16px;
    height: 40px;
    max-width: 240px;
    width: 100%;
    padding-right: 40px;
    padding-left: 16px;

    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &::placeholder {
      color: rgba(0, 0, 0, 0.25);
      font-size: 14px;
    }
  }

  .input-box {
    max-width: 240px;
    width: 100%;
    height: 40px;
    position: relative;

    &::after {
      content: 'TRY';
      display: inline-block;
      width: 25px;
      height: 16px;
      position: absolute;
      color: #d9d9d9;
      font-size: 14px;
      top: 10px;
      right: 12px;
    }
  }

  .close {
    position: absolute;
    top: 30px;
    right: 30px;
  }

  .title {
    padding: 0;
    margin-top: 16px;
    font-size: 20px;
    font-weight: 400px;
  }

  .input-and-text-box {
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: center;
    margin-top: 24px;
  }

  .btn__green {
    margin-top: 24px;
    max-width: 240px;
  }
}
