// .line_chart {
//     // display: flex;
//     // width: 100%;
//     // flex-direction: column;
//     // justify-self: end;
//     // border-radius: 8px;
//     // background-color: #fff;
//     // position: relative;
//     // padding: 8px;
//     // height: 100%;
//     // overflow: hidden;
//     // border: 1px solid #E0E7F2;
//     // gap: 20px;

//     .title {
//         color: #020617;
//         font-family: Rubik;
//         font-size: 16px;
//         font-style: normal;
//         font-weight: 500;
//         line-height: 20px;
//     }

//     .chart-box {
//         display: flex;
//         // min-height: 300px;
//         // max-height: 300px;
//         height: 100%;
//         width: 100%;
//     }

//     & canvas {
//         max-height: calc(300px);
//     }

//     &_header {
//         display: flex;
//         width: 100%;
//         justify-content: end;

//         .btn-reset-zoom {
//             height: 32px;
//             padding-left: 8px;
//             padding-right: 8px;
//             right: 16px;
//             background-color: #F1F5F9;
//             font-size: 14px;
//             border-radius: 8px;
//             border: 1.5px solid lightgrey;
//         }
//     }
// }


// .header_icon_placeholder {
//     display: flex;
//     gap: .3em;
// }

// .header_icon_placeholder img {
//     width: .9em;
//     height: .9em;
//     cursor: pointer;
// }

// .line_chart_heading_big {
//     width: 100%;
//     display: flex;
//     justify-content: space-between;
// }

// .line_chart_heading {
//     display: flex;
//     align-items: center;
// }

// .chart_title_icon {
//     width: .85em;
//     height: .85em;
//     margin-left: 1em;
// }

// .chart_loader_wrapper {
//     width: 100%;
//     height: 400px;
//     display: flex;
//     justify-content: center;
//     align-items: center;
// }

.line_chart__no-data {
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: center;
    justify-content: center;
    padding: 8px;
}