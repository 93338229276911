.acc-auth {

    .MuiInputBase-root {
        margin-top: 28px;
    }
    &__block {
        display: flex;
        flex-direction: column;
        gap: 10px;
        width: 100%;
    }

    &__title {
        font-size: 14px;
    }

    &__input-field {
        width: 100%;
       height: 100%;
        font-family: 'Inter';
        font-size: 14px;
        padding: 16px 20px;
        margin-top: 4px; border: 1px solid #F0F0F0;
        border-radius: 16px;

        &::placeholder {
            font-size: 14px;
            font-family: "Inter";
            color: #b3b3b3;
        }
    }

    &__description {
        color: #757575;
        font-size: 12px;
    }
}

.modal-autorization__content {
    
    .add_connection_box_wrapper {

    max-width: 704px;
    display: flex;
    gap: 24px;
    min-height: 350px;
    height: auto;
    max-height: calc(100vh - 120px - 190px);
    background-color: #F5F5F5;
}
}
