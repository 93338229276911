.information-for-billing {
    display: flex;
    width: 100%;
    flex-direction: column;
    max-width: 800px;
    gap: 24px;

    .title__main {
        color: #0C2037;
        font-family: Rubik;
        font-size: 26px;
        font-style: normal;
        font-weight: 500;
        line-height: 32px;
        letter-spacing: -0.56px;
        margin-bottom: 12px;
    }

    &__content {
        display: flex;
        width: 100%;
        flex-direction: column;
        gap: 24px;
        padding: 24px 32px;
        flex-direction: column;
        border-radius: 8px;
        border: 2px solid #F2F2F4;
        max-width: 800px;   
        background-color: #fff;
    }

    .button-copy {
        top: calc(50% - 8px);
    }

    .btn__blue {
        max-width: 286px;
        min-width: 286px;
        margin-left: auto;
    }

    .tooltip-box {
        position: absolute;
        top: calc(50% - 8px);
        right: 0;

        .tooltip_black {
            opacity: 0;
            visibility: hidden;
            background-color: #4A4A4A;
            border-radius: 2px;
            overflow: hidden;
            color: white;
            position: absolute;
            top: 90%;
            left: calc(30px);
            z-index: 100;
            width: max-content;
            height: auto;
            padding: 2px 4px;
            border: 1px solid #E0E7F2;
        }

        &:hover {
            .tooltip_black {
                opacity: 1;
                visibility: visible;
                transition: 0.5s;
            }
        }
    }

    .non-editable-fields {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-top: 32px;

        &__item {
            padding: 20px;
            border-bottom: 1px solid #E0E7F2;

            &:nth-child(1) {
                border-top: 1px solid #E0E7F2;
            }

            &:last-child {
                border-bottom: none;
            }

            .val-box {
                display: flex;
                flex-direction: column;
                width: max-content;
                padding-right: 32px;
                position: relative;

                .tooltip-box {
                    position: absolute;
                    top: calc(50% - 8px);
                    right: 0;

                    .tooltip_black {
                        opacity: 0;
                        visibility: hidden;
                        background-color: #4A4A4A;
                        border-radius: 2px;
                        overflow: hidden;
                        color: white;
                        position: absolute;
                        top: 90%;
                        left: calc(30px);
                        z-index: 100;
                        width: max-content;
                        height: auto;
                        padding: 2px 4px;
                        border: 1px solid #E0E7F2;
                    }

                    &:hover {
                        .tooltip_black {
                            opacity: 1;
                            visibility: visible;
                            transition: 0.5s;
                        }
                    }

                    .btn-copy {
                        background-image: url('./img/copy.svg');
                        width: 16px;
                        height: 16px;
                        min-width: 16px;
                        border: none;
                        background-color: inherit;
                        background-repeat: no-repeat;
                        background-position: center;
                        position: absolute;
                    }
                }
            }

            span {
                width: auto;
                color: var(--main-menu-secondary-text, #475569);
                font-family: Inter;
                font-size: 11px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }

            .text {
                width: auto;
                color: var(--main-menu-primary-text, #020617);
                font-family: Inter;
                font-size: 15px;
                font-style: normal;
                font-weight: 500;
                line-height: 24px;
            }
        }
    }

    .non-editable-fields-group {
        display: flex;
        gap: 20px;
        border-bottom: 1px solid #E0E7F2;

        .non-editable-fields__item {
            width: calc(50% - 10px);
            border: none;
        }
    }

    .title {
        color: var(--main-menu-primary-text, #020617);
        /* Заголовки/h3 */
        font-family: Inter;
        font-size: 22px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    .btn__blue {
        width: 152px;
        height: 52px;
    }

    .group-btn {
        display: flex;
        justify-content: end;
        gap: 20px;
        width: 100%;
        margin-top: 30px;

        .btn__white,
        .btn__blue {
            margin: 0;
            width: 100%;
            max-width: 183px;
            min-width: 183px;
        }
    }

    .input-group {
        display: flex;
        gap: 20px;
    }
}