.payment {
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;
    height: 100%;
    position: relative;

    .content-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;
    }

    &__content {
        display: flex;
        gap: 12px;
        flex-wrap: wrap;
    }

    &__promocode {
        display: flex;
        width: calc(50% - 6px) ;
        min-width: 470px;
        padding: 24px;
        flex-direction: column;
        align-items: flex-start;
        gap: 24px;
        justify-content: space-between;
        border-radius: 10px;
        border: 2px solid #F2F2F4;
        background: #FFF;
    }

    &__balance {
        display: flex;
        width: calc(50% - 6px) ;
        min-width: 470px;
        padding: 24px;
        flex-direction: column;
        align-items: flex-start;
        gap: 24px;
        justify-content: space-between;
        border-radius: 10px;
        border: 2px solid #F2F2F4;
        background: #FFF;
    }

    &__balance-content {
        display: flex;
        height: 73px;
        padding: 0px 20px;
        justify-content: space-between;
        align-items: center;
        align-self: stretch;
        border-radius: 10px;
        background: #F6F8FF;
    }

    &__promocode-content {
        display: flex;
        width: 100%;
        flex-direction: column;
        gap: 2px;
    }

    &__sum {
        white-space: nowrap;
        color: #365EFF;
        font-family: "Golos Text";
        font-size: 32px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }

    &__placeholder {
        color: #0C2037;
        font-family: "Golos Text";
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
    }

    &__input-box {
        display: flex;
        width: 100%;
        padding: 8px 16px;
        justify-content: space-between;
        border-radius: 10px;
        border: 2px solid #F2F2F4;
        background: #FFF;
        gap: 24px;
    }

    &__input {
        width: calc(100% - 150px - 24px);
        color: black;
        font-family: "Golos Text";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        border: none;

        &::placeholder {
            color: #A5A6A8;
        }

        &:focus {
            outline: none;
        }
    }

    &__balance-content {
        display: flex;
        width: 100%;
        gap: 24px;
        justify-content: space-between;
    }

    &__title-min {
        color: #0C2037;
        font-family: "Golos Text";
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }

    &__title {
        color: #0C2037;
        font-family: Rubik;
        font-size: 28px;
        font-style: normal;
        font-weight: 500;
        line-height: 32px;
        letter-spacing: -0.56px;
        margin-bottom: 12px;
    }
}