@import url('./common/styles/buttons.scss');
@import url('./common/styles/modal.scss');
@import url('./common/styles/DatePicker.scss');
@import url('./common/styles/breadcrumbs.scss');

// Open Sans

@font-face {
    font-family: "Open Sans";
    src: url("../public/font/OpenSans/OpenSans-Light.ttf");
    font-weight: 300;
    font-style: normal;
  }
  
  @font-face {
    font-family: "Open Sans";
    src: url("../public/font/OpenSans/OpenSans-Regular.ttf");
    font-weight: 400;
    font-style: normal;
  }
  
  @font-face {
    font-family: "Open Sans";
    src: url("../public/font/OpenSans/OpenSans-Medium.ttf");
    font-weight: 500;
    font-style: normal;
  }
  
  @font-face {
    font-family: "Open Sans";
    src: url("../public/font/OpenSans/OpenSans-SemiBold.ttf");
    font-weight: 600;
    font-style: normal;
  }
  
  @font-face {
    font-family: "Open Sans";
    src: url("../public/font/OpenSans/OpenSans-Bold.ttf");
    font-weight: 700;
  }
    

.search-global-input{
    z-index: 2 !important;
}

.loader_wrapper {
    width: 100%;
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid grey;
}

.product-box-content-main {
    display: flex;
    margin-top: 60px;
    justify-content: center;
}

* {
    box-sizing: border-box;  
    font-family: 'Open Sans' !important;

    svg {
        color: 'inherit';
    }
}

button {
    cursor: pointer;
}

main {
    * {

        &::-webkit-scrollbar {
            width: 12px;
            height: 12px;
        }
    
        &::-webkit-scrollbar-track-piece {
            background-color: #fff;
        }
    
        &::-webkit-scrollbar-thumb:vertical {
            width: 12px;
            height: 12px;
            background-color: #636D65;
            border-radius: 12px;
            scrollbar-width: none;
        }
    
        &::-webkit-scrollbar-thumb:horizontal {
            height: 12px;
            background-color: #636D65;
            border-radius: 12px;
        }
    
        //    &::-webkit-scrollbar-button:single-button:vertical:decrement {
        //     background-color: #FAFBFA;
        //     border-radius: 0px;
        //     background-image: url('./pages/TaskList/img/Up.svg');
        //     background-position: center;
        //     background-repeat: no-repeat;
        //   }
    
        //   &::-webkit-scrollbar-button:single-button:vertical:increment {
        //     background-color: #FAFBFA;
        //     border-radius: 0px;
        //     background-image: url('./pages/TaskList/img/Down.svg');
        //     background-position: center;
        //     background-repeat: no-repeat;
        //   }
    
        //   &::-webkit-scrollbar-button:single-button:horizontal:decrement {
        //     background-color: #FAFBFA;
        //     border-radius: 0px;
        //     background-image: url('./pages/TaskList/img/Left.svg');
        //     background-position: center;
        //     background-repeat: no-repeat;
        //   }
    
        //   &::-webkit-scrollbar-button:single-button:horizontal:increment {
        //     background-color: #FAFBFA;
        //     border-radius: 0px;
        //     background-image: url('./pages/TaskList/img/Right.svg');
        //     background-position: center;
        //     background-repeat: no-repeat;
        //   }
    }
}

html {
  scroll-behavior: smooth;
}
