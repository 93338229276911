.account-safety {
  display: flex;
  flex-direction: column;
  gap: 12px;

  .title {
    color: #0c2037;
    font-family: Rubik;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin: 0;
  }

  .link__blue {
    display: inline-block;
    width: 100%;
    text-align: right;
    color: #1890ff;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-decoration: underline;
    margin-left: auto;
    margin-top: 4px;
  }
}
