.create-RICH-content-inside-content {
    display: flex;
    width: 100%;
    gap: 24px;

    .content-item {
        display: flex;
        width: 100%;
        flex-direction: column;
        align-items: flex-end;
        width: calc(50% - 12px);
        gap: 8px;

        .content-btn {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 8px;
        }

        .rich-item {
            display: flex;
            width: 100%;
            padding: 16px 20px;
            flex-direction: column;
            align-items: flex-start;
            gap: 12px;
            border-radius: 8px;
            border: 2px solid #EDEFED;
            background: #FFF;

            &__header {
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                width: 100%;
                justify-content: space-between;
                gap: 24px;

                .title {
                    color: #020617;
                    font-family: NunitoSans;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 24px;
                }

                .btn-group {
                    display: flex;
                    align-items: center;
                    flex-wrap: wrap;
                    gap: 12px;

                    .btn {
                        display: flex;
                        align-items: center;
                        gap: 10px;
                        cursor: pointer;
                        background-color: inherit;
                        border: none;
                        color: #4285F4;
                        font-family: NunitoSans;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 16px
                    }
                }
            }

            &__content {
                display: flex;
                flex-direction: column;
                gap: 12px;

                .img-group {
                    display: flex;
                    gap: 24px;
                    flex-wrap: wrap;

                    .img-box {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 290px;
                        min-width: 290px;
                        height: 336px;

                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: contain;
                        }

                        .not-img {
                            width: 40px;
                            height: 40px;
                        }
                    }


                }

                .title {
                    overflow: hidden;
                    color: #020617;
                    text-overflow: ellipsis;
                    font-family: NunitoSans;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 20px;
                }

                .text {
                    color: #64748B;
                    font-family: NunitoSans;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 20px;
                }
            }
        }
    }
}