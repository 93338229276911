.dynamic-element {
    display: flex;
    align-items: center;
    gap: 2px;
    padding: 2px;
    border-radius: 4px;
    font-family: Inter;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;

    &__bottom {
        background: #FFE5E5;
        color: #EB4D4D;
    }

    &__top {
        background: #E5FFEE;
        color: #2ED166;
    }
}