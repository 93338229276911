.MuiTooltip-popper {
    z-index: 5 !important;

    .css-8y3x9n {
        padding: 8px;
        background-color: #0161B9;
        color: var(--greyscale-100, #E1E6EA);
        text-align: right;
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
    }

    .text_white {
        color: var(--greyscale-100, #E1E6EA);
        text-align: right;
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
    }

    .css-1urvb1y {
        color: #0161B9;
    }

    .css-1k51tf5-MuiTooltip-tooltip {
        padding: 8px;
        background-color: #0161B9;
        color: var(--greyscale-100, #E1E6EA);
        text-align: right;
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;

    }

    .css-kudwh-MuiTooltip-arrow {

        &::before {
            background-color: #0161B9 !important;
        }
    }
}

.MuiSlider-root:hover {
    color: #2e8b57;
}

.blue-tooltip-mui {
    background-color: #0161B9;
}