.btn__social-media {
    cursor: pointer;
    display: flex;
    gap: 16px;
    width: 100%;
    height: 64px;
    border: 1px solid #F0F0F0;
    border-radius: 8px;
    background-color: white;
    justify-content: center;
    align-items: center;
    color: #020617;
    border: 1px solid var(--unnamed, #F0F0F0);
    max-width: 274px;

    &:hover {
        box-shadow: 2px 3px 12px 0px rgba(0, 0, 0, 0.04);
        transition: all 0.3s;
    }

    img {
        display: inline-block;
    }
} 

.btn-group__social-media {
    display: flex;
    gap: 12px;
    width: 100%;
    max-width: 560px;
}