.top-brands {
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;

    .charts-container {
        width: 100%;
    }
}