@import 'src/common/styles/svg_img.scss';

.text_grey {
    color: #888;
    font-size: 12px;
}

.analysis-category {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    gap: 24px;

    .checkbox-container {
        display: flex;
        gap: 8px;
        align-items: center;

        .text_label,
        .text_label_disabled {
            color: #020617;
            font-family: Rubik;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 16px;

            &_disabled {
                color: #D3DEE7;
            }
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        gap: 24px;
        width: 100%;
        max-width: calc(1440px - 120px);
        padding-left: 20px;
        padding-right: 20px;
        margin-left: auto;
        margin-right: auto;
    }

    .range-group-btn-and-range-box {
        display: flex;
        align-items: center;
        gap: 12px;

        .selector_wrapper {
            max-width: 124px;
            min-width: 124px;

            .css-13cymwt-control,
            .css-t3ipsp-control {
                background-color: #F1F5F9;

                &:hover {
                    background-color: #E1E6EA;
                }
            }
        }

        .drop-box {
            display: flex;
            align-items: center;
            gap: 16px;
        }
    }

    .charts-container {
        display: flex;
        width: 100%;
        height: 100%;
        gap: 8px;
    }

    .tooltip-blue {
        margin-top: 12px;
    }

    .toolbar-top {
        display: flex;
        width: 100%;
        gap: 16px;
        flex-wrap: wrap;
        align-items: center;
        flex-wrap: wrap;

        .show-breadcrumbs-btn {
            max-width: 250px;
        }

        .breadcrumbs-container {
            display: flex;
            flex-direction: row;
            width: calc(100% - 340px);
            align-self: center;
            gap: 30px;
        }

        .filter-group {
            display: flex;
            column-gap: 12px;
            row-gap: 8px;
            align-items: center;
            flex-wrap: wrap;
            gap: 16px;
            width: calc(100% - 245px - 16px);
            min-width: 416px;

            .selector_wrapper {
                max-width: 210px;
                min-width: 210px;
            }
        }

        .selector_wrapper {
            max-width: 159px;
            min-width: 159px;
        }
    }

    .toolbar-medium {
        display: flex;
        width: 100%;
        justify-content: space-between;
        gap: 16px;
        flex-wrap: wrap;
        align-items: flex-end;

        .range-group-btn-and-range-box {
            display: flex;
            align-items: center;
            gap: 30px;

            .selector_wrapper {
                max-width: 124px;
                min-width: 124px;

                .css-13cymwt-control,
                .css-t3ipsp-control {
                    background-color: #F1F5F9;

                    &:hover {
                        background-color: #E1E6EA;
                    }
                }
            }
        }
    }

    .tab-box {
        display: flex;
        width: auto;
        row-gap: 12px;
        flex-wrap: wrap;

        .btn__tab,
        .btn__tab_active {
            cursor: pointer;
            height: 34px;
            min-width: 130px;
            width: auto;
            font-size: 16px;
            font-weight: 500;
            background-color: inherit;
            border: none;
            padding-right: 20px;
            padding-left: 20px;
            white-space: nowrap;
            line-height: 20px;
            border-bottom: 2px solid #E0E7F2;

            &:hover {
                transition: 0.5s;
                border-bottom: 2px solid #1890FF;
                color: #1890FF;
            }

            &_active {
                border-bottom: 2px solid #1890FF;
                color: #1890FF;
            }
        }
    }

    .table-box-component {
        align-items: flex-start;
    }

    .pie-chart {
        margin-top: 16px;
    }

}

.analysis-category {
    .table-box-component {
        //  width: min-content;
        align-items: flex-start;
    }
}