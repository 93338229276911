@import 'src/common/styles/svg_img.scss';

.offer-and-contract {
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;
    height: 100%;
    margin-left: auto;
    margin-right: auto;

    .title__main {
        color: #0C2037;
        font-family: Rubik;
        font-size: 26px;
        font-style: normal;
        font-weight: 500;
        line-height: 32px;
        letter-spacing: -0.56px;
        margin-bottom: 12px;
    }

    &__box {
        display: flex;
        flex-direction: column;
        gap: 16px;
        width: 100%;
    }

    &__bottom-box {
        display: flex;
        width: 100%;
        gap: 32px;
        margin-top: 40px;
    }

    &__item {
        width: 100%;
        min-height: 72px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: white;
        border-radius: 16px 27px 16px 20px;
        padding: 16px;
        gap: 20px;

        .inner-content {
            display: flex;
            align-items: center;
            gap: 12px;

            .text-box {
                display: flex;
                flex-direction: column;
                gap: 8px;

                .text {
                    font-size: 13px;
                }

                .text_grey {
                    font-size: 11px;
                    color: #888888;
                }
            }
        }

        .btn-group {
            width: auto;
            display: flex;
            gap: 27px;
            align-items: center;
        }
    }

    .title {
        font-size: 13px;
        font-weight: 400;
    }
}

.btn__download,
.btn__load,
.btn__copy {
    width: 16px;
    min-width: 16px;
    height: 16px;
    background-image: url($download4);
    background-size: 16px;
    background-repeat: no-repeat;
    background-position: center;
    background-color: inherit;
    border: none;
    cursor: pointer;
}

.btn__load {
    background-image: url($load);
}